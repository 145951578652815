import type { PayloadAction } from '@reduxjs/toolkit';
import { createSlice } from '@reduxjs/toolkit';
import type { IClothingItem, TGender } from '@typings';

export interface IClothingState {
	wardrobeItems: Record<string, (IClothingItem & { isSelected: boolean })[]> | null;
	selectedClothes: IClothingItem[];
	uploadedClothes: IClothingItem[];
	gender: TGender;
	bestOutfits: string[];
}

const initialState: IClothingState = {
	wardrobeItems: null,
	selectedClothes: [],
	uploadedClothes: [],
	gender: 'feminine',
	bestOutfits: [],
};

export const clothingSlice = createSlice({
	name: 'clothingSlice',
	initialState: initialState,
	reducers: {
		setUploadedClothes: (state: IClothingState, { payload: uploadedClothes }: PayloadAction<IClothingItem[]>) => {
			state.uploadedClothes = [...state.uploadedClothes, ...uploadedClothes];
		},
		removeUploadedCloth(state, action: PayloadAction<number>) {
			state.uploadedClothes = state.uploadedClothes.filter((_, index) => index !== action.payload);
		},
		setSelectedClothes: (state: IClothingState, { payload: clothes }: PayloadAction<IClothingItem[]>) => {
			state.selectedClothes = clothes;
		},
		setWardrobeItems: (
			state: IClothingState,
			{ payload: wardrobeItems }: PayloadAction<IClothingState['wardrobeItems']>,
		) => {
			state.wardrobeItems = wardrobeItems;
		},
		toggleWardrobeItemSelection: (
			state: IClothingState,
			{ payload }: PayloadAction<{ category: string; itemName: string }>,
		) => {
			if (!state.wardrobeItems) return;
			const items = state.wardrobeItems[payload.category];
			if (items) {
				state.wardrobeItems[payload.category] = items.map((item) =>
					item.name === payload.itemName ? { ...item, isSelected: !item.isSelected } : item,
				);
			}
		},
		// Reset all items in a category to unselected
		resetWardrobeItemsSelection: (state: IClothingState, { payload: category }: PayloadAction<string>) => {
			if (!state.wardrobeItems) return;
			const items = state.wardrobeItems[category];
			if (items) {
				state.wardrobeItems[category] = items.map((item) => ({
					...item,
					isSelected: false,
				}));
			}
		},
		setGender: (state: IClothingState, { payload: gender }: PayloadAction<TGender>) => {
			state.gender = gender;
		},
		setBestOutfits: (state: IClothingState, { payload: bestOutfits }: PayloadAction<string[]>) => {
			state.bestOutfits = bestOutfits;
		},
		removeBestOutfit: (state: IClothingState, { payload: bestOutfitUrl }: PayloadAction<string>) => {
			state.bestOutfits = state.bestOutfits.filter((url) => url !== bestOutfitUrl);
		},
	},
});
