import { ErrorBoundary } from '../ErrorBoundary';
import { Helmet } from 'react-helmet';
import { APP_TITLE } from '@constants';
import { useEffect } from '@hooks';
import type { ReactNode } from 'react';

/** Top-level wrapper for page components. */
const Page = ({ children, title, description, image, scrollDisabled }: IPageProps) => {
	useEffect(() => {
		if (scrollDisabled) {
			document.body.style.overflow = 'hidden';
		} else {
			document.body.style.overflow = 'auto';
		}
		// Cleanup on unmount
		return () => {
			document.body.style.overflow = 'auto';
		};
	}, [scrollDisabled]);

	// @todo make this reusable
	const currentUrl = `https://www.lookify.app${location.pathname}${location.hash}`;

	return (
		<>
			<Helmet>
				<title>
					{title} | {APP_TITLE}
				</title>
				<meta content={description} name="description" />
				<meta content={title} property="og:title" />
				<meta content={description} property="og:description" />
				<meta content={image} property="og:image" />
				<meta content={currentUrl} property="og:url" />
				<meta content="website" property="og:type" />
			</Helmet>

			<ErrorBoundary>{children}</ErrorBoundary>
		</>
	);
};

export interface IPageProps {
	children: ReactNode;
	title: string;
	description?: string;
	image?: string;
	scrollDisabled?: boolean;
}

export default Page;
