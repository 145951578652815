import React from 'react';
import type { ReactNode } from 'react';
import type { ProviderProps } from '@reactour/tour';
import { TourProvider } from '@reactour/tour';
import { disableBodyScroll, enableBodyScroll } from 'body-scroll-lock';

const TourWrapper: React.FC<IJoyrideTourProps> = ({ steps, children, onClickHighlighted }) => {
	const disableBody = (target: Element | null) => disableBodyScroll(target as Element);
	const enableBody = (target: Element | null) => enableBodyScroll(target as Element);

	return (
		<TourProvider
			afterOpen={disableBody}
			beforeClose={enableBody}
			components={{ Badge: () => null }}
			disableInteraction={true}
			showNavigation
			steps={steps}
			styles={{
				maskArea: (base) => ({ ...base, rx: 15 }),
			}}
			onClickHighlighted={onClickHighlighted}
		>
			{children}
		</TourProvider>
	);
};

export interface IJoyrideTourProps {
	steps: ProviderProps['steps'];
	children: ReactNode;
	onClickHighlighted?: ProviderProps['onClickHighlighted'];
}

export default TourWrapper;
