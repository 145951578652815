import React from 'react';
import { FullScreenPageLayout, Page } from '../../components';
import { useTranslation } from '@hooks';
import styles from './BestOutfitsPage.module.css';

import BestOutfitContent from '@ui-modules/demo/components/BestOutfitContent';
import { useIsMutating } from '@tanstack/react-query';
import { ActivityIndicator, Button } from '@ui-kit';
import { useNavigate } from 'react-router-dom';
import { ROUTES } from '@constants';
import { useGetBestOutfitsConvexQuery } from '@ui-modules/demo/hooks/useGetBestOutfitsConvexQuery';
import type { TReviewForm } from '@ui-modules/demo/components/ReviewForm';
import ReviewForm from '@ui-modules/demo/components/ReviewForm';
import { Formik } from 'formik';
import { bestOutfitReviewSchemas } from '@schemas';
import { useAddGroupReviewMutation } from '@ui-modules/demo/hooks/useAddGroupReviewMutation';
import { v4 as uuidv4 } from 'uuid';

const BestOutfitsPage = () => {
	const { t } = useTranslation();

	const { data: bestOutfits, isLoading, isFetched, isError } = useGetBestOutfitsConvexQuery();
	const {
		mutateAsync: addGroupReview,
		isSuccess: isReviewSuccess,
		isLoading: isReviewLoading,
	} = useAddGroupReviewMutation();

	const navigate = useNavigate();

	const isMutating = useIsMutating(['wardrobe.getBestOutfits']);

	const initialValues: TReviewForm = {
		review: '',
	};

	const onSubmit = async (values: TReviewForm) => {
		await addGroupReview({
			review: values.review,
			groupUrls: bestOutfits?.map((outfit) => outfit.outfit_url) || [],
			id: uuidv4(),
		});
	};

	if (isError) {
		return null;
	}

	return (
		<Page title={t('My Wardrobe')}>
			<div className={styles.wardrobe__contentWrapper}>
				<FullScreenPageLayout
					backButtonEnabled
					headerTitle={t('Best Outfit{{plural}}', { plural: Number(bestOutfits?.length) > 1 ? 's' : '' })}
					isFixed={false}
				>
					<div className={styles.wardrobe__contentWrapper}>
						<div className={styles.wardrobe__container}>
							{!!bestOutfits?.length && (
								<div className={styles.wardrobe__discoverWrapper}>
									<h3 className={styles.wardrobe__discover}>
										<span className={styles.wardrobe__discoverFirstHighlight}>{t('Your style unlocked')}</span>,{' '}
										{t('discover the')} <span className={styles.wardrobe__discoverSecondHighlight}>{t('looks')}</span>{' '}
										{t('made for you')}.
									</h3>
								</div>
							)}
							{!!isMutating && <h3>{t('New outfits are being generated')}...</h3>}

							{isLoading ? (
								<div className={styles.bestOutfits__activityIndicator}>
									<ActivityIndicator />
								</div>
							) : (
								isFetched && <BestOutfitContent bestOutfits={bestOutfits || []} />
							)}

							{!!bestOutfits?.length && (
								<Formik<TReviewForm>
									initialValues={initialValues}
									validationSchema={bestOutfitReviewSchemas(t)}
									onSubmit={onSubmit}
								>
									{(formProps) => (
										<ReviewForm formProps={formProps} isLoading={isReviewLoading} isSuccess={isReviewSuccess} />
									)}
								</Formik>
							)}
							{!isLoading && isFetched && !bestOutfits?.length && (
								<div className={styles.bestOutfits__emptyState}>
									<Button
										size={'large'}
										title={t(
											'You do not have any best outfits yet. Add items from the Clothing Selection page, then generate them in your wardrobe.',
										)}
										titleClassName={styles.bestOutfits__emptyStateTitle}
										variant="link"
										onClick={() => navigate(ROUTES.clothingSelection())}
									/>
								</div>
							)}
						</div>
					</div>
				</FullScreenPageLayout>
			</div>
		</Page>
	);
};

export default BestOutfitsPage;
