import { useTranslation } from '@hooks';
import styles from './ReviewForm.module.css';
import type { FormikProps } from 'formik';
import { Button } from '@ui-kit';
import TextArea from '@ui-kit/components/TextArea';

const ReviewForm = ({
	formProps,

	isSuccess,
	isLoading,
}: IReviewFormProps) => {
	const { t } = useTranslation();

	const { values, setFieldValue, errors, isValid, handleSubmit } = formProps;

	return (
		<form className={styles.reviewForm__container} onSubmit={handleSubmit}>
			<div className={styles.reviewForm__titleWrapper}>
				<h3 className={styles.reviewForm__title}>
					<span className={styles.reviewForm__how}>{t('How')}</span> {t('did we do? Share your style')}{' '}
					<span className={styles.reviewForm__experience}>{t('experience')}</span>
				</h3>
				<p>
					We’d love to hear your thoughts! Let us know how these looks fit your style and help us improve your
					experience.
				</p>
			</div>
			<div className={styles.reviewForm__reviewWrapper}>
				<TextArea
					disabled={isSuccess}
					errorMessage={errors.review}
					errorMessagePosition={'right'}
					maxRows={8}
					minRows={8}
					placeholder={t('Enter your thoughts...')}
					rounded
					value={values.review}
					onChange={(e) => setFieldValue('review', e.target.value)}
				/>
				<Button
					block={'fit'}
					disabled={!values.review?.length || !isValid || isSuccess}
					loading={isLoading}
					title={t('Submit')}
					type="submit"
					variant="secondary"
				/>
			</div>
		</form>
	);
};

export interface IReviewFormProps {
	formProps: FormikProps<TReviewForm>;
	isSuccess: boolean;
	isLoading: boolean;
}

export type TReviewForm = { review: string };

export default ReviewForm;
