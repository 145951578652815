import { UikitContext } from '@ui-kit/contexts/UikitContext';
import { useService, useMemo } from '@hooks';
import type { PropsWithChildren } from 'react';
import type { IUikitContextValue } from '@ui-kit/contexts/UikitContext';
import type { TMixpanelEvent } from '@typings';

export const UikitContextProvider = ({ children }: PropsWithChildren) => {
	const analytics = useService('AnalyticsService');

	const contextValue: IUikitContextValue = useMemo(
		() => ({
			trackClickEvent: (ctaName: TMixpanelEvent) =>
				analytics.trackEvent('User_Action', { action_type: 'navigation', action_name: ctaName }),
		}),
		[],
	);

	return <UikitContext.Provider value={contextValue}>{children}</UikitContext.Provider>;
};
