import type { IAxiosService } from '../interfaces/AxiosService.interface';
import type { ImageType } from 'react-images-uploading';
import type { IImageClassified } from '../../common/typings/internal/Classified.types';

export class MediaService {
	static inject = ['AxiosService', 'AnalyticsService'] as const;
	constructor(private readonly axios: IAxiosService) {}

	async removeBackground(images: ImageType[], user_id: string) {
		const formData = new FormData();
		formData.append('user_id', user_id);
		images.forEach((image) => formData.append('images', image.file as Blob));
		try {
			const response: { imageLinks: string[] } = await this.axios.post('/remove-background', formData);
			return response.imageLinks;
		} catch (error) {
			console.error('Error removing background:', error);
			throw error;
		}
	}

	async classifyImages(imageNames: string[], user_id: string): Promise<IImageClassified> {
		const payload = {
			imageNames,
			user_id,
		};

		try {
			return await this.axios.post('/classify', payload);
		} catch (error) {
			console.error('Error classifying images:', error);
			throw error;
		}
	}

	async getClassifiedImage(user_id: string, name: string): Promise<Blob> {
		try {
			const url = `/${user_id}/images/${name}`;

			const response = await this.axios.get(url, {
				responseType: 'blob',
			});

			if (!(response instanceof Blob)) {
				throw new Error('Response is not a valid Blob');
			}

			return response; // Return the valid Blob
		} catch (error) {
			console.error('Error fetching image:', error);
			throw error;
		}
	}
}
