import { useState, useEffect, useCallback } from 'react';

const breakPoint = {
	xs: 'xs',
	s: 's',
	m: 'm',
	l: 'l',
	xl: 'xl',
};

// Screen Size Hook
const useScreenSize = () => {
	const isClient = typeof window === 'object';
	const getSize = useCallback(() => {
		return {
			width: isClient ? window.innerWidth : 0,
			height: isClient ? window.innerHeight : 0,
			screen: breakPoint.s,
		};
	}, [isClient]);

	const [screenSize, setScreenSize] = useState(getSize);

	useEffect(() => {
		if (!isClient) {
			return;
		}

		function handleResize() {
			setScreenSize(getSize());
		}

		window.addEventListener('resize', handleResize);
		return () => window.removeEventListener('resize', handleResize);
	}, []);

	if (screenSize.width < 576) {
		screenSize.screen = breakPoint.xs;
	} else if (screenSize.width >= 576 && screenSize.width < 768) {
		screenSize.screen = breakPoint.s;
	} else if (screenSize.width >= 768 && screenSize.width < 992) {
		screenSize.screen = breakPoint.m;
	} else if (screenSize.width >= 992 && screenSize.width < 1200) {
		screenSize.screen = breakPoint.l;
	} else {
		screenSize.screen = breakPoint.xl;
	}

	return screenSize;
};

export { breakPoint, useScreenSize };
