import { saveAs } from 'file-saver';

export async function downloadImages(imageUrls: string[]) {
	// For each image URL, fetch the file and trigger a download
	const downloadPromises = imageUrls.map(async (url, index) => {
		try {
			// 1) Fetch the image
			const response = await fetch(url);
			if (!response.ok) {
				throw new Error(`Failed to fetch image: ${url}`);
			}

			// 2) Convert to Blob
			const blob = await response.blob();

			// Extract a filename from the URL or build your own
			const urlParts = url.split('/');
			const fileName = urlParts[urlParts.length - 1] || `image-${index}`;

			// 3) Trigger download using FileSaver
			saveAs(blob, fileName);
		} catch (error) {
			console.error('Error downloading image:', error);
		}
	});

	// Wait for all downloads to finish
	await Promise.all(downloadPromises);
}
