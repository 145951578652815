import type { IAppConfig } from '../interfaces/AppConfig.interface';

import type { ConvexClientService } from './ConvexClientService';
import type { IBestOutfit } from '@typings';
import { api } from '@convex/_generated/api';
import { addGroupReview } from '@convex/reviews';
import type { TReviewForm } from '@ui-modules/demo/components/ReviewForm';

/** Service collects methods to perform http-request to backend API. */
export class BestOutfitConvex {
	static inject = ['AppConfigService', 'ConvexClientService'] as const;

	constructor(private readonly appConfig: IAppConfig, private readonly convexClientService: ConvexClientService) {}

	public async addBestOutfit({ user_id, outfit_url, rating, id, creationTime }: IBestOutfit): Promise<void> {
		try {
			await this.convexClientService.mutation(api.best_outfits.addBestOutfit, {
				id,
				creationTime,
				user_id,
				outfit_url,
				rating: rating || 0,
			});
		} catch (error) {
			console.error('Error adding outfit:', error);
			throw error;
		}
	}

	public async updateBestOutfitRating({ rating, id }: { rating: number; id: string }): Promise<void> {
		try {
			await this.convexClientService.mutation(api.best_outfits.updateRating, {
				rating: rating || 0,
				id,
			});
		} catch (error) {
			console.error('Error updating outfit rating:', error);
			throw error;
		}
	}

	public async getBestOutfits({ user_id }: { user_id: string }): Promise<IBestOutfit[]> {
		try {
			return await this.convexClientService.query(api.best_outfits.getBestOutfits, {
				user_id: user_id,
			});
		} catch (error) {
			console.error('Error updating outfit rating:', error);
			throw error;
		}
	}

	public async deleteOutfit({ user_id, id }: { user_id: string; id: string }): Promise<void> {
		try {
			await this.convexClientService.mutation(api.best_outfits.deleteOutfit, {
				user_id: user_id,
				id: id,
			});
		} catch (error) {
			console.error('Error updating outfit rating:', error);
			throw error;
		}
	}

	public async addGroupReview({
		id,
		review,
		groupUrls,
	}: TReviewForm & { groupUrls: string[]; id: string }): Promise<void> {
		try {
			await this.convexClientService.mutation(api.reviews.addGroupReview, {
				id,
				review,
				groupUrls,
			});
		} catch (error) {
			console.error('Error adding group review:', error);
			throw error;
		}
	}
}
