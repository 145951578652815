import { clsx } from '@utils';
import styles from './Checkbox.module.css';
import type { ReactNode } from 'react';

const Checkbox = ({
	value = false,
	size = 'medium',
	disabled = false,
	children,
	errored = false,
	onChange,
	filterCheckbox,
	className,
	checkedClassName,
	icon: Icon,
}: ICheckboxProps) => {
	const handleChange = () => {
		onChange(!value);
	};

	return (
		<label className={clsx(styles.checkbox, disabled && styles.checkbox_disabled)}>
			<input
				checked={value}
				className={styles.checkbox__input}
				disabled={disabled}
				type="checkbox"
				onChange={handleChange}
			/>
			<span
				className={clsx(
					disabled && styles.checkbox__icon_disabled,
					filterCheckbox && styles.checkbox__icon_filter,
					styles.checkbox__icon,
					errored && styles.checkbox__icon_error,
					value && styles.checkbox__icon_active,
					className,
				)}
				data-size={size}
				// FIXME Mark this as role="checked" and input as aria-hidden cause failing of 1 test.
				// eslint-disable-next-line jsx-a11y/no-noninteractive-tabindex
				tabIndex={0}
				onKeyPress={handleChange}
			>
				{value ? (
					<div className={clsx(styles.checkbox__icon_checked, checkedClassName)} data-size={size}>
						{Icon}
					</div>
				) : null}
			</span>
			{children}
		</label>
	);
};

export interface ICheckboxProps {
	/** is it checked or not.  */
	value: boolean;
	/** medium or small size of the checkbox. Default medium. */
	size?: 'medium' | 'small';
	/* If true forbids change value.*/
	disabled?: boolean;
	/** Title of the checkbox displayed on the right side. */
	children?: ReactNode;
	/** Highlights input with red color. */
	errored?: boolean;
	/** If true shows horizontal line. */
	showHorizontalRule?: boolean;
	/** Returns a new checked value */
	onChange: (newValue: boolean) => void;
	/** Optional flag for checkboxes on Deal Filter page */
	filterCheckbox?: boolean;
	/** Additional custom style for check box */
	className?: string;
	/** Additional custom style for check box's inner icon */
	checkedClassName?: string;
	/** Custom inner icon */
	icon?: ReactNode;
}

export default Checkbox;
