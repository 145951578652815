import { ConvexReactClient } from 'convex/react';
import type {
	ArgsAndOptions,
	FunctionArgs,
	FunctionReference,
	FunctionReturnType,
	OptionalRestArgs,
} from 'convex/server';
import type { MutationOptions } from 'convex/react';

export class ConvexClientService {
	static inject = [''];
	public readonly convexClient: ConvexReactClient;

	constructor() {
		const convexUrl = import.meta.env.VITE_CONVEX_URL;

		if (!convexUrl) {
			throw new Error('Convex URL must be provided.');
		}
		this.convexClient = new ConvexReactClient(convexUrl);
	}

	/**
	 * Executes a query function on the Convex backend.
	 * @param query - The query function reference (from `api`).
	 * @param args - Arguments for the query.
	 */
	public async query<Query extends FunctionReference<'query'>>(
		query: Query,
		...args: OptionalRestArgs<Query>
	): Promise<FunctionReturnType<Query>> {
		try {
			return await this.convexClient.query(query, ...args);
		} catch (error: any) {
			throw new Error(`Query failed: ${error.message}`);
		}
	}

	/**
	 * Executes a mutation function on the Convex backend.
	 * @param mutation - The mutation function reference (from `api`).
	 * @param argsAndOptions - Arguments and options for the mutation.
	 */
	public async mutation<Mutation extends FunctionReference<'mutation'>>(
		mutation: Mutation,
		...argsAndOptions: ArgsAndOptions<Mutation, MutationOptions<FunctionArgs<Mutation>>>
	): Promise<FunctionReturnType<Mutation>> {
		try {
			return await this.convexClient.mutation(mutation, ...argsAndOptions);
		} catch (error: any) {
			throw new Error(`Mutation failed: ${error.message}`);
		}
	}
}
