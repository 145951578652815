import { jwtDecode } from 'jwt-decode';
import type { CredentialResponse } from '@react-oauth/google';
import type { ReduxService } from './ReduxService';
import type { TUser, IDecodedUserQueryReturnType } from '@typings';

export class AuthService {
	static inject = ['ReduxService'] as const;

	constructor(private readonly redux: ReduxService) {}

	async signIn(credentialResponse: CredentialResponse): Promise<TUser> {
		if (!credentialResponse.credential) {
			throw new Error('Google credential is missing.');
		}

		const decodedToken: IDecodedUserQueryReturnType = jwtDecode(credentialResponse.credential);

		// Validate the audience
		if (decodedToken.aud !== '49050452165-0tappki0uf6bmllvtk3bmhtvd5bltdar.apps.googleusercontent.com') {
			throw new Error('Invalid audience in Google token.');
		}

		// Validate the issuer
		if (decodedToken.iss !== 'https://accounts.google.com') {
			throw new Error('Invalid issuer in Google token.');
		}

		// Validate token expiration
		const now = Math.floor(Date.now() / 1000);
		if ((decodedToken.exp as number) < now) {
			throw new Error('Google token has expired.');
		}

		this.saveUser(decodedToken);
		return decodedToken;
	}

	async signOut() {
		await this.resetUser();
	}

	protected saveUser(tokens: IDecodedUserQueryReturnType) {
		this.redux.store.dispatch(
			this.redux.meSlice.saveAccount({
				email: tokens.email,
				emailVerified: tokens.email_verified,
				fullName: tokens.name,
				profilePicture: tokens.picture,
				firstName: tokens.given_name,
				lastName: tokens.family_name,
				id: tokens.sub,
			}),
		);
		this.redux.store.dispatch(this.redux.authSlice.setAuthAuthorized());
	}

	protected resetUser() {
		this.redux.store.dispatch(this.redux.meSlice.removeAccount());
		this.redux.store.dispatch(this.redux.authSlice.setAuthUnauthorized());
	}
}
