import type { IClothingItem, IImageClassified } from '@typings';

/**
 * Converts classified data and associated background-removed images into a list of clothing items.
 * This function dynamically determines the image extension based on the provided background-removed image URLs,
 * fetches the classified images as Blobs, generates object URLs for them, and constructs the final clothing item objects.
 *
 * @param {IImageClassified} data - The classified data containing information about clothing items.
 * @param {string | undefined} userId - The user ID required to fetch the images.
 * @param {(userId: string, name: string) => Promise<Blob>} getClassifiedImage - A function to fetch an image Blob for a given user ID and image name.
 * @param {string[]} backgroundRemovedImages - A list of URLs for background-removed images to extract extensions and validate the mapping.
 *
 * @returns {Promise<IClothingItem[]>} A promise resolving to a list of clothing items with image URLs, names, and categories.
 *
 * @throws {Error} Throws an error if userId is undefined or if fetching an image fails.
 */
export const convertDataToImageList = async (
	data: IImageClassified,
	userId: string | undefined,
	getClassifiedImage: (userId: string, name: string) => Promise<Blob>,
	backgroundRemovedImages: string[],
): Promise<IClothingItem[]> => {
	if (!userId) throw new Error('User ID is required to fetch images.');

	// Map image keys to their corresponding extensions (png, jpg, etc.)
	const imageExtensions = backgroundRemovedImages.reduce((acc, url) => {
		const fileName = url.split('/').pop(); // Extract the file name
		const extension = fileName?.split('.').pop(); // Extract the file extension
		const nameWithoutExtension = fileName?.replace(/\.[^/.]+$/, ''); // Remove the extension
		if (nameWithoutExtension && extension) {
			acc[nameWithoutExtension] = extension;
		}
		return acc;
	}, {} as Record<string, string>);

	// Helper function to convert Blob to Base64
	const blobToBase64 = (blob: Blob): Promise<string> => {
		return new Promise((resolve, reject) => {
			const reader = new FileReader();
			reader.onloadend = () => resolve(reader.result as string);
			reader.onerror = reject;
			reader.readAsDataURL(blob);
		});
	};

	return (await Promise.all(
		Object.entries(data).map(async ([key, value]) => {
			const transformedKey = `${key}_bg_removed`;
			const extension = imageExtensions[transformedKey] || 'png';
			const fileName = `${key}_bg_removed.${extension}`;
			const category = value.main_category.replace(/[<>]/g, '').toLowerCase();

			const imageBlob = await getClassifiedImage(userId, fileName);

			const base64Url = await blobToBase64(imageBlob);
			return {
				image_url: base64Url,
				name: fileName,
				category,
			};
		}),
	)) as unknown as Promise<IClothingItem[]>;
};
