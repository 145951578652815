import { dayjs } from '@utils';
import type { ImageType } from 'react-images-uploading';

export const renameImages = (imageList: ImageType[]) => {
	return imageList.map((image, index) => {
		if (image.file) {
			// Create a new file with the updated name
			const newFileName = `${dayjs().toISOString()}_${index + 1}.${image.file.name.split('.').pop()}`;
			const renamedFile = new File([image.file], newFileName, {
				type: image.file.type,
			});

			return {
				...image,
				file: renamedFile,
			};
		}
		return image;
	});
};
