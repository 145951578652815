import React, { useRef } from 'react';
import type { SheetRef } from 'react-modal-sheet';
import { Sheet } from 'react-modal-sheet';
import styles from './BottomSheet.module.css';
const BottomSheet: React.FC<IBottomSheetProps> = ({
	isOpen,
	onClose,
	snapPoints = [700, 500, 300],
	initialSnap = 0,
	disableDrag = false,
	disableScrollLocking = false,
	children,
	headerContent,
	hasOverlay = true,
	containerClassName,
}) => {
	const sheetRef = useRef<SheetRef | null>(null);

	return (
		<Sheet
			className={styles.bottomSheet}
			disableDrag={disableDrag}
			disableScrollLocking={disableScrollLocking}
			initialSnap={initialSnap}
			isOpen={isOpen}
			ref={sheetRef}
			snapPoints={snapPoints}
			onClose={() => onClose?.()}
		>
			<Sheet.Container className={containerClassName}>
				{headerContent && <Sheet.Header>{headerContent}</Sheet.Header>}
				<Sheet.Content>{children}</Sheet.Content>
			</Sheet.Container>
			{hasOverlay ? <Sheet.Backdrop onTap={onClose} /> : <></>}
		</Sheet>
	);
};

export interface IBottomSheetProps {
	isOpen: boolean;
	onClose?: () => void;
	hasOverlay?: boolean;
	snapPoints?: number[]; // Defines snap points for custom positioning
	initialSnap?: number; // Sets the initial snap point index
	disableDrag?: boolean; // Disables drag functionality if true
	disableScrollLocking?: boolean; // Disables scroll locking when open
	children: React.ReactNode; // Content to render inside the sheet
	headerContent?: React.ReactNode; // Optional custom header content
	containerClassName?: string; // Optional className container wrapper
}

export default BottomSheet;
