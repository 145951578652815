import { createContext } from 'react';
import { noop } from '@utils';
import type { TMixpanelEvent } from '@typings';

export const UikitContext = createContext<IUikitContextValue>({
	trackClickEvent: noop,
});

export interface IUikitContextValue {
	trackClickEvent: (ctaName: TMixpanelEvent) => void;
}
