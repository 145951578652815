import { useQuery, useService } from '@hooks';
import { dayjs } from '@utils';
import type { UseQueryOptions } from '@tanstack/react-query';
import type { ICategory, TGender } from '@typings';

export const useGetCategoriesQuery = <T = TUseGetCategoriesQueryData>(
	params: TUseGetCategoriesQueryParams,
	queryOptions?: UseQueryOptions<TUseGetCategoriesQueryData, Error, T>,
) => {
	const api = useService('ApiService');

	return useQuery<TUseGetCategoriesQueryData, Error, T>(
		['getCategories', params.gender],
		() => api.clothing.getCategories(params),
		{
			cacheTime: dayjs.duration(30, 'minutes').asMilliseconds(),
			staleTime: dayjs.duration(30, 'minutes').asMilliseconds(),
			...queryOptions,
		},
	);
};
type TUseGetCategoriesQueryParams = { gender: TGender };
type TUseGetCategoriesQueryData = ICategory[];
