import type { ImageType } from 'react-images-uploading';
import heic2any from 'heic2any';

export const convertHEICToJPG = async (imageList: ImageType[]) =>
	await Promise.all(
		imageList.map(async (image) => {
			if (image.file && image.file.type === 'image/heic') {
				try {
					const convertedBlob = (await heic2any({
						blob: image.file,
						toType: 'image/jpeg',
					})) as Blob;

					const convertedFile = new File([convertedBlob], image.file.name.replace(/\..+$/, '.jpg'), {
						type: 'image/jpeg',
					});
					return {
						...image,
						file: convertedFile,
						image_url: URL.createObjectURL(convertedFile),
					};
				} catch (error) {
					console.error('Error converting HEIC to JPEG:', error);
					return image;
				}
			}
			return image;
		}),
	);
