import { useService } from '@services';

export const useWalkthroughShown = () => {
	const { getItem, setItem } = useService('LocalStorageService');

	const getWalkthroughShownCategories = async () => {
		return await getItem('walkthroughShown__categories');
	};

	const getWalkthroughShownWardrobe = async () => {
		return await getItem('walkthroughShown__wardrobe');
	};

	const setWardrobeWalkthroughShown = async () => {
		await setItem('walkthroughShown__wardrobe', 'true');
	};

	const setCategoriesWalkthroughShown = async () => {
		await setItem('walkthroughShown__categories', 'true');
	};

	return {
		getWalkthroughShownCategories,
		getWalkthroughShownWardrobe,
		setWardrobeWalkthroughShown,
		setCategoriesWalkthroughShown,
	};
};
