import React, { useState } from 'react';
import styles from '@ui-modules/demo/components/CategoryFilter/CategoryFilter.module.css';
import { BottomSheet, Button, DoubleChevronBottomIcon, DoubleChevronTopIcon, TrashIcon, WardrobeIcon } from '@ui-kit';
import { useTranslation } from 'react-i18next';
import { useNavigate } from 'react-router-dom';
import type { FormikProps } from 'formik';
import type { TDemoFormValues } from '../../../../app/pages/ClothingSelectionPage/ClothingSelectionPage';
import { useClothingActions } from '@ui-modules/demo/hooks/useClothingActions';
import { useToggle, useWindowSize } from 'react-use';
import ImageUploader from '../ImageUploader';
import { useProcessImagesMutation } from '@ui-modules/demo/hooks/useProcessIImagesMutation';
import { ROUTES } from '@constants';
import { groupClothingItems } from '@ui-modules/demo/utils/groupByCategory';
import RCImage from 'rc-image';
import { useNotification, useService } from '@hooks';
import type { ImageType } from 'react-images-uploading';

const SelectedClothesBottomSheet = ({ isSheetOpen, formProps }: ISelectedClothesBottomSheetProps) => {
	const navigate = useNavigate();
	const analytics = useService('AnalyticsService');

	const { values } = formProps;

	const { t } = useTranslation();
	const { width } = useWindowSize();

	const { setUploadedClothes, uploadedClothes, removeUploadedImage, setWardrobeItems, gender } = useClothingActions();
	const { showError } = useNotification();

	const [expanded, toggleBottomSheet] = useToggle(true);

	const maxNumber = 20;

	const { mutateAsync: mutateProcessImages, isLoading } = useProcessImagesMutation({});
	const [isCompressing, setIsCompressing] = useState(false);

	const bottomSheetHeight = width <= 768 ? (isLoading || uploadedClothes?.length > 0 ? 240 : 159) : 128;
	const handleImagesChange = async (imageList: ImageType[]) => {
		if (uploadedClothes.length + imageList.length > maxNumber) {
			showError({
				title: t('Upload Limit Exceeded'),
				subtitle: t(`You can upload a maximum of {{maxNumber}} images.`, { maxNumber }),
			});
			return;
		}

		try {
			setIsCompressing(true);

			const processedImages = await mutateProcessImages({ images: imageList as unknown as Blob[] });

			setUploadedClothes(processedImages);
		} catch (error) {
			showError({
				title: t('Processing Error'),
				subtitle: t('There was an issue processing your images. Please try again.'),
			});
		} finally {
			setIsCompressing(false);
		}
	};

	return (
		<>
			<BottomSheet
				containerClassName={styles.categoryFilter__wardrobeBottomSheetContainer}
				disableDrag
				disableScrollLocking
				hasOverlay={false}
				initialSnap={0}
				isOpen={!isSheetOpen}
				snapPoints={[expanded ? bottomSheetHeight : 0]}
			>
				<div className={styles.categoryFilter__wardrobeSelectorContainer}>
					<div className={styles.categoryFilter__wardrobeSheet}>
						<ImageUploader
							images={uploadedClothes}
							imageUploaderTitle={t('Upload Clothing')}
							isLoading={isLoading || isCompressing}
							renderImage={({ image, index }) => {
								return (
									<div className={styles.imageUploader__imageItem} key={index}>
										<RCImage
											alt=""
											height={90}
											preview={false}
											src={image.image_url}
											onError={() => console.error(`Failed to load image: ${image.image_url}`)}
										/>
										<Button
											className={styles.imageUploader__trash}
											icon={<TrashIcon fill="var(--color-error)" />}
											size="extra_small"
											variant="tertiary"
											onClick={async () => {
												removeUploadedImage(index);
											}}
										/>
									</div>
								);
							}}
							separator
							onImagesChange={handleImagesChange}
						/>

						<Button
							badge={
								uploadedClothes?.length + values?.selectedClothes?.length !== 0 && (
									<div className={styles.wardrobe__itemCounter}>
										{uploadedClothes?.length + values?.selectedClothes?.length}
									</div>
								)
							}
							className={styles.wardrobe__toWardrobe}
							disabled={(!values.selectedClothes?.length && !uploadedClothes?.length) || isLoading}
							icon={<WardrobeIcon className={styles.wardrobeIcon} />}
							title={t('My Wardrobe')}
							variant="tertiary"
							onClick={async () => {
								const clothes = [...(values?.selectedClothes || []), ...uploadedClothes];
								const groupedClothes = groupClothingItems(clothes);

								setWardrobeItems(groupedClothes);

								analytics.trackEvent('Added_To_Wardrobe', {
									outfits_count: clothes?.length,
									outfits: clothes,
									gender: gender,
									hardcoded_addition_count: values?.selectedClothes?.length || 0,
									uploaded_addition_count: uploadedClothes?.length || 0,
								});

								navigate(ROUTES.myWardrobe());
							}}
						/>
					</div>
					{expanded && (
						<span
							className={styles.categoryFilter__collapse}
							role="button"
							tabIndex={0}
							onClick={toggleBottomSheet}
							onKeyPress={toggleBottomSheet}
						>
							<DoubleChevronBottomIcon color="#fff" />
						</span>
					)}
				</div>
			</BottomSheet>
			{!expanded && (
				<span
					className={styles.categoryFilter__expand}
					role="button"
					tabIndex={0}
					onClick={toggleBottomSheet}
					onKeyPress={toggleBottomSheet}
				>
					{uploadedClothes?.length + values?.selectedClothes?.length !== 0 && (
						<div className={styles.categoryFilter__selectedClothesBadge}>
							{values?.selectedClothes?.length + uploadedClothes?.length}
						</div>
					)}
					<DoubleChevronTopIcon color="black" />
				</span>
			)}
		</>
	);
};

export interface ISelectedClothesBottomSheetProps {
	formProps: FormikProps<TDemoFormValues>;
	isSheetOpen: boolean;
}

export default SelectedClothesBottomSheet;
