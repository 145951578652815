import { useEffect } from 'react';
import { useScreenSize, useTranslation } from '@hooks';
import styles from './ClothingSelector.module.css';
import { VirtuosoGrid } from 'react-virtuoso';
import { ActivityIndicator, Button, Modal } from '@ui-kit';
import { useGetCategoryQuery } from '@ui-modules/demo/hooks/useGetCategoryQuery';
import { useClothingActions } from '@ui-modules/demo/hooks/useClothingActions';
import { gridComponents } from './utils';
import { useToggle } from 'react-use';
import CategoryFilter from '@ui-modules/demo/components/CategoryFilter';

import type { FormikProps } from 'formik';
import type { TDemoFormValues } from '../../../../app/pages/ClothingSelectionPage/ClothingSelectionPage';
import type { ICategory, IClothingItem } from '@typings';
import ClothingItemCard from '../ClothingItemCard';

const ClothingSelector = ({ formProps }: IClothingSelectorProps) => {
	const { t } = useTranslation();

	const { values, setFieldValue } = formProps;

	const { width } = useScreenSize();

	const { setSelectedClothes } = useClothingActions();

	const { data: categories, isLoading } = useGetCategoryQuery({
		name: values.activeCategoryName,
		gender: values.gender,
	});

	const [showDialog, toggleShowDialog] = useToggle(false);

	useEffect(() => {
		if (values?.selectedClothes?.length) {
			setSelectedClothes(values.selectedClothes);
		}
	}, [values?.selectedClothes]);

	const onDiscard = () => {
		setSelectedClothes([]);
		setFieldValue('selectedClothes', []);
		toggleShowDialog();
	};

	const onSelect = (selectedCloth: ICategory) => {
		// Check if the item is already selected
		const isAlreadySelected = values.selectedClothes.some(({ name }) => name === selectedCloth.name);

		// If already selected, remove it; otherwise, add it with the current active category
		const updatedSelectedClothes: IClothingItem[] = isAlreadySelected
			? values.selectedClothes.filter(({ name }) => name !== selectedCloth.name)
			: [
					...values.selectedClothes,
					{
						...selectedCloth,
						category: values?.activeCategoryName?.toLowerCase() || '',
					},
			  ];

		setFieldValue('selectedClothes', updatedSelectedClothes);
		setSelectedClothes(updatedSelectedClothes);
	};

	return (
		<div className={styles.clothingSelector__container}>
			<div className={styles.clothingSelector__titleContainer}>
				<div className={styles.clothingSelector__titleWrapper}>
					<h4>{t('Select clothing')}</h4>
					<Button
						disabled={values?.selectedClothes.length === 0}
						size={width > 1024 ? 'medium' : 'small'}
						title={t('Unselect all')}
						titleClassName={styles.clothingSelector__title}
						variant="secondary"
						onClick={() => toggleShowDialog()}
					/>
				</div>
			</div>
			{width <= 1024 && <CategoryFilter formProps={formProps} />}
			{isLoading ? (
				<div className={styles.clothingSelector__activityIndicatorWrapper}>
					<ActivityIndicator />
				</div>
			) : (
				<div className={styles.clothingSelector__gridContainer}>
					<VirtuosoGrid
						components={gridComponents}
						computeItemKey={(index, item) => `${item.name}_${index}`}
						data={categories || []}
						initialItemCount={categories?.length || 0}
						itemContent={(index, item) => {
							return (
								<ClothingItemCard
									isSelected={Boolean(values?.selectedClothes?.find((cloth) => cloth.name === item.name))}
									item={item}
									key={index}
									onSelect={onSelect}
								/>
							);
						}}
						/*@todo change the inline styles*/
						style={{ height: '700px', width: '100%' }}
						totalCount={categories?.length || 0}
					/>
				</div>
			)}
			<Modal
				cancelTitle={t('Yes, remove')}
				confirmTitle={t('No, keep them')}
				subTitle={t('These clothes have been part of your style journey. Are you sure you want to say goodbye?')}
				title={t('Letting Go of Style')}
				variant="medium"
				visible={showDialog}
				onCancel={onDiscard}
				onConfirm={() => {
					toggleShowDialog();
				}}
			/>
		</div>
	);
};

export interface IClothingSelectorProps {
	formProps: FormikProps<TDemoFormValues>;
}

export default ClothingSelector;
