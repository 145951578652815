import { useScreenSize } from '@hooks';
import { Button, CloseIcon, HamburgerIcon } from '@ui-kit';

import { useMenu } from './hooks/TabBar.useMenu';
import { useNavigation } from './hooks/TabBar.useNavigation';

import Logo from './components/Logo';
import SmallTabBar from './components/SmallTabBar';
import LargeTabBar from './components/LargeTabBar';

import styles from './TabBar.module.css';
import { useLocation } from 'react-router-dom';
import { ROUTES } from '@constants';

const TabBar = () => {
	const { width } = useScreenSize();
	const { activeNav, handleNavigation } = useNavigation();
	const { menuOpen, setMenuOpen, toggleMenu } = useMenu();
	const location = useLocation();

	return (
		<header className={styles.header}>
			<div className={styles.header__container} id={styles['header']}>
				<div className={styles.header__wrapper}>
					<Logo />
					{location.pathname === ROUTES.home() && (
						<>
							{width > 1024 ? (
								<LargeTabBar activeNav={activeNav} handleNavigation={handleNavigation} />
							) : (
								<Button
									icon={menuOpen ? <CloseIcon height={20} width={20} /> : <HamburgerIcon height={20} width={20} />}
									size="extra_small"
									variant="clear"
									onClick={toggleMenu}
								/>
							)}
						</>
					)}
				</div>

				{menuOpen && width <= 1024 && (
					<SmallTabBar
						activeNav={activeNav}
						handleNavigation={handleNavigation}
						menuOpen={menuOpen}
						setMenuOpen={setMenuOpen}
					/>
				)}
			</div>
		</header>
	);
};

export default TabBar;
