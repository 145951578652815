import React from 'react';

import { BestOutfit } from '@ui-kit';

import styles from './BestOutfitContent.module.css';
import type { IBestOutfit } from '@typings';
// import { groupBestOutfitsWithDate } from '@ui-modules/demo/utils/groupBestOutfitsWithDate';

const BestOutfitContent = ({ bestOutfits }: IBestOutfitContentProps) => {
	return (
		<div className={styles.bestOutfitContent__container}>
			<div className={styles.container}>
				{bestOutfits?.map((outfit) => (
					<BestOutfit key={outfit.id} {...outfit} />
				))}
			</div>
		</div>
	);
};

interface IBestOutfitContentProps {
	bestOutfits: IBestOutfit[];
}

export default BestOutfitContent;
