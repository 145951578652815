import type { IAxiosService } from '../interfaces/AxiosService.interface';
import type { ICategory, TGender } from '@typings';

/** Service collects methods to perform http-request to backend API. */
export class ClothingService {
	static inject = ['AxiosService'] as const;
	constructor(private readonly axios: IAxiosService) {}

	public async getCategories(params: { gender: TGender }): Promise<ICategory[]> {
		let map = 'female';
		if (params.gender === 'feminine') {
			map = 'female';
		} else if (params.gender === 'masculine') {
			map = 'male';
		}
		return await this.axios.get(`/clothing?gender=${map}`);
	}

	public async getCategoryById(params: { name: ICategory['name']; gender: TGender }): Promise<ICategory[] | undefined> {
		let genderMap = 'female';
		if (params.gender === 'feminine') {
			genderMap = 'female';
		} else if (params.gender === 'masculine') {
			genderMap = 'male';
		}
		return await this.axios.get(`/categories?gender=${genderMap}&category=${params.name}`);
	}

	public async getBestOutfits(userID: string, imageNames: string[]): Promise<{ bestOutfitUrls: string[] }> {
		try {
			return await this.axios.post('/best-outfit', {
				imageNames: imageNames,
				user_id: userID,
			});
		} catch (error) {
			console.error('Error fetching the best outfit:', error);
			throw error;
		}
	}
}
