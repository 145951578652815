import { useService, useMutation, useNotification, useMe } from '@hooks';
import type { TReviewForm } from '@ui-modules/demo/components/ReviewForm';
import { useTranslation } from 'react-i18next';

export const useAddGroupReviewMutation = () => {
	const bestOutfitConvex = useService('BestOutfitConvex');
	const { showUnknownError, showSuccess } = useNotification();

	const { user } = useMe();
	const { t } = useTranslation();

	return useMutation<unknown, Error, TReviewForm & { groupUrls: string[]; id: string }>(
		['rateBestOutfit', user.id],
		async ({ id, groupUrls, review }) =>
			await bestOutfitConvex.addGroupReview({
				id,
				groupUrls,
				review,
			}),
		{
			onSuccess: () => {
				showSuccess({ title: t('Review submitted successfully!') });
			},
			onError: (error) => {
				showUnknownError(error);
			},
		},
	);
};
