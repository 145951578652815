import { LoadingSkeleton } from '@ui-kit';
import CategoryRow from '../CategoryRow';
import styles from './CategoryList.module.css';
import type { ICategory } from '@typings';

const CategoryList = ({ categories, activeCategoryName, onCategorySelect, isLoading }: ICategoryListProps) => {
	if (isLoading) {
		return (
			<div className={styles.categoryFilter__activityIndicatorWrapper}>
				<LoadingSkeleton height={54} lines={1} width="100%" />
			</div>
		);
	}

	return (
		<>
			{categories?.map((category) => (
				<CategoryRow
					category={category}
					isActive={category.name === activeCategoryName}
					key={category.name}
					onCategorySelect={onCategorySelect}
				/>
			))}
		</>
	);
};
export interface ICategoryListProps {
	categories: ICategory[] | undefined;
	activeCategoryName?: string | null;

	onCategorySelect: (categoryId: string) => void;
	isLoading: boolean;
}

export default CategoryList;
