import type { IAppConfig } from '../interfaces/AppConfig.interface';
import { ValidationError } from 'yup';
import * as yup from 'yup';
/** Parses and collects environment variables to share with another services. */
export class AppConfigService implements IAppConfig {
	static inject = [] as const;
	constructor() {
		try {
			const parsedConfig = this.#environmentSchema.validateSync(
				{
					NODE_ENV: import.meta.env.VITE_NODE_ENV,
					API_BASE_URL: import.meta.env.VITE_API_BASE_URL,
					APP_ENV: import.meta.env.VITE_APP_ENV,
					APP_HIDDEN: import.meta.env.VITE_APP_HIDDEN,
					DEBUG: import.meta.env.VITE_DEBUG,
					SENTRY_DSN: import.meta.env.VITE_SENTRY_DSN,
					MIX_PANEL_TOKEN: import.meta.env.VITE_MIX_PANEL_TOKEN,
					VITE_EMAIL_SERVICE_ID: import.meta.env.VITE_EMAIL_SERVICE_ID,
					VITE_EMAIL_TEMPLATE_ID: import.meta.env.VITE_EMAIL_TEMPLATE_ID,
					VITE_EMAIL_PUBLIC_KEY: import.meta.env.VITE_EMAIL_PUBLIC_KEY,
				},
				{
					abortEarly: false,
					stripUnknown: true,
				},
			);

			// Setup env vars.
			this.NODE_ENV = parsedConfig.NODE_ENV;
			this.API_BASE_URL = parsedConfig.API_BASE_URL;
			this.APP_ENV = parsedConfig.APP_ENV;
			this.APP_HIDDEN = parsedConfig.APP_HIDDEN;
			this.DEBUG = parsedConfig.DEBUG;
			this.SENTRY_DSN = parsedConfig.SENTRY_DSN;
			this.MIX_PANEL_TOKEN = parsedConfig.MIX_PANEL_TOKEN;
			this.VITE_EMAIL_SERVICE_ID = parsedConfig.VITE_EMAIL_SERVICE_ID;
			this.VITE_EMAIL_TEMPLATE_ID = parsedConfig.VITE_EMAIL_TEMPLATE_ID;
			this.VITE_EMAIL_PUBLIC_KEY = parsedConfig.VITE_EMAIL_PUBLIC_KEY;
		} catch (error) {
			if (error instanceof ValidationError) {
				console.info('Validation error', error.errors);
			}
			throw error;
		}
	}

	#environmentSchema = yup.object({
		NODE_ENV: yup.mixed<NodeJS.ProcessEnv['NODE_ENV']>().oneOf(['development', 'production', 'test']).required(),
		APP_ENV: yup.mixed<NodeJS.ProcessEnv['APP_ENV']>().oneOf(['dev', 'production', 'qa']).required(),
		API_BASE_URL: yup.string().required(),
		APP_HIDDEN: yup.boolean().default(false).required(),
		DEBUG: yup.boolean().default(false).required(),
		SENTRY_DSN: yup.string().required(),
		MIX_PANEL_TOKEN: yup.string().required(),
		VITE_EMAIL_SERVICE_ID: yup.string().required(),
		VITE_EMAIL_TEMPLATE_ID: yup.string().required(),
		VITE_EMAIL_PUBLIC_KEY: yup.string().required(),
	});

	readonly NODE_ENV: NodeJS.ProcessEnv['NODE_ENV'];
	readonly APP_ENV: NodeJS.ProcessEnv['APP_ENV'];
	readonly API_BASE_URL: NodeJS.ProcessEnv['API_BASE_URL'];
	readonly APP_HIDDEN: boolean;
	readonly DEBUG: boolean;
	readonly SENTRY_DSN: string;
	readonly MIX_PANEL_TOKEN: string;
	readonly VITE_EMAIL_SERVICE_ID: string;
	readonly VITE_EMAIL_TEMPLATE_ID: string;
	readonly VITE_EMAIL_PUBLIC_KEY: string;
}
