import styles from './BestOutfit.module.css';
import StarsRating from '../StarsRating';
import React, { useState } from 'react';
import Image from 'rc-image';
import { useRateBestOutfitMutation } from '@ui-modules/demo/hooks/useRateBestOutfitMutation';
import type { IBestOutfit } from '@typings';
import { useDeleteBestOutfitConvexMutation } from '@ui-modules/demo/hooks/useDeleteBestOutfitConvexMutation';
import { Button } from '@ui-kit/components';
import { TrashIcon } from '@ui-kit/icons';

const BestOutfit = ({ outfit_url, rating: storedRating, id }: IBestOutfitProps) => {
	const [rating, setRating] = useState(storedRating || 0);
	const { mutateAsync: rateOutfit } = useRateBestOutfitMutation();
	const { mutateAsync: deleteOutfit, isLoading: isDeleteLoading } = useDeleteBestOutfitConvexMutation();

	return (
		<div className={styles.bestOutfit__container}>
			<Image
				alt="Best Outfit"
				className={styles.bestOutfitContent__bestImage}
				preview={false}
				src={outfit_url}
				onError={() => console.error(`Failed to load image: ${outfit_url}`)}
			/>
			<div className={styles.bestOutfit__ratingWrapper}>
				<StarsRating
					rating={rating || 0}
					onClick={async (rating) => {
						await rateOutfit({ id, rating });
						setRating(rating);
					}}
				/>
				<Button
					className={styles.bestOutfit__trash}
					disabled={isDeleteLoading}
					icon={<TrashIcon fill={'var(--color-accent)'} height={24} width={24} />}
					loading={isDeleteLoading}
					size={'extra_small'}
					variant={'clear'}
					onClick={async () => {
						await deleteOutfit({ id });
					}}
				/>
			</div>
		</div>
	);
};

export interface IBestOutfitProps extends IBestOutfit {}

export default BestOutfit;
