import { clsx } from '@utils';
import styles from './CategoryRow.module.css';
import type { ICategory } from '@typings';
import { useClothingActions } from '@ui-modules/demo/hooks/useClothingActions';

const CategoryRow = ({ category, isActive, onCategorySelect }: ICategoryRowProps) => {
	const selectedCategories = [];

	const { selectedClothes } = useClothingActions();

	return (
		<button className={clsx(styles.row, isActive && styles.active)} onClick={() => onCategorySelect(category.name)}>
			<div className={styles.info}>
				<span className={clsx(styles.name, isActive && styles.activeName)}>{category.title}</span>
				{/*<span className={styles.minCount}>*/}
				{/*	/!*@todo declare minimum selection count*!/*/}
				{/*	{t('min.')} {category?.min_count || 3}*/}
				{/*</span>*/}
			</div>
			<div className={clsx(styles.counter, !!selectedCategories?.length && styles.counter_on)}>
				{selectedClothes.filter((item) => item.category === category.name)?.length}
			</div>
		</button>
	);
};

export interface ICategoryRowProps {
	category: ICategory;
	isActive: boolean;
	onCategorySelect: (categoryName: ICategory['name']) => void;
}

export default CategoryRow;
