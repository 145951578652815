import { useService, useMutation, useNotification, useMe } from '@hooks';
import type { IBestOutfit } from '@typings';

export const useRateBestOutfitMutation = () => {
	const bestOutfitConvex = useService('BestOutfitConvex');
	const { showUnknownError } = useNotification();

	const { user } = useMe();

	return useMutation<unknown, Error, Omit<{ id: string; rating: number }, 'user_id'>>(
		['rateBestOutfit', user.id],
		async ({ id, rating }) =>
			await bestOutfitConvex.updateBestOutfitRating({
				rating: rating || 0,
				id: id,
			}),
		{
			onError: (error) => {
				showUnknownError(error);
			},
		},
	);
};
