import type { IClothingItem } from '@typings';

export const groupClothingItems = (
	items: IClothingItem[],
): Record<string, (IClothingItem & { isSelected: boolean })[]> => {
	return items.reduce((acc, item) => {
		if (!acc[item.category]) {
			acc[item.category] = [];
		}
		acc[item.category].push({ ...item, isSelected: true });
		return acc;
	}, {} as Record<string, (IClothingItem & { isSelected: boolean })[]>);
};
