import React, { useCallback, useEffect, useLayoutEffect, useMemo, useState } from 'react';

import { useScreenSize, useTranslation } from '@hooks';
import { ArrowRightIcon, BottomSheet, Button, CloseIcon, LoadingSkeleton } from '@ui-kit';
import { useGetCategoriesQuery } from '@ui-modules/demo/hooks/useGetCategoriesQuery';

import GenderSelector from '@ui-modules/demo/components/GenderSelector';
import SelectedClothesBottomSheet from '@ui-modules/demo/components/CategoryFilter/SelectedClothesBottomSheet';

import styles from './CategoryFilter.module.css';
import type { FormikProps } from 'formik';
import type { TDemoFormValues } from '../../../../app/pages/ClothingSelectionPage/ClothingSelectionPage';
import type { TGender } from '@typings';
import { useClothingActions } from '@ui-modules/demo/hooks/useClothingActions';
import CategoryList from '../CategoryList';
import { useTour } from '@reactour/tour';
import { useWalkthroughShown } from '@ui-modules/demo/hooks/useWalkthroughShown';

const CategoryFilter: React.FC<ICategoryFilterProps> = ({ formProps }) => {
	const { t } = useTranslation();
	const { setIsOpen, currentStep, steps } = useTour();
	const { setFieldValue, values } = formProps;
	const [isSheetOpen, setIsSheetOpen] = useState(false);

	const { data: categories, isLoading } = useGetCategoriesQuery({ gender: values.gender });

	const { width } = useScreenSize();
	const { setGender } = useClothingActions();

	const selectedCategory = useMemo(
		() => categories?.find((item) => item.name === values.activeCategoryName),
		[values.activeCategoryName, categories],
	);

	const onGenderSelect = useCallback(
		async (gender: TGender) => {
			setGender(gender);
			setFieldValue('gender', gender);
			setFieldValue('activeCategoryName', null);
		},
		[setFieldValue, setGender],
	);

	const handleCategorySelect = useCallback(
		(categoryId: string) => {
			setFieldValue('activeCategoryName', categoryId);
			if (width <= 1024) {
				setIsSheetOpen(false);
			}
		},
		[setFieldValue, width],
	);

	const { getWalkthroughShownCategories, setCategoriesWalkthroughShown } = useWalkthroughShown();

	useEffect(() => {
		const checkWalkthrough = async () => {
			const walkthroughShownCategories = await getWalkthroughShownCategories();

			if (!walkthroughShownCategories) {
				setIsOpen(true);
			}
		};
		checkWalkthrough();
	}, []);

	const isDesktop = width > 1024;

	useEffect(() => {
		if (currentStep + 1 === steps?.length) {
			setCategoriesWalkthroughShown();
		}
	}, [currentStep]);

	useLayoutEffect(() => {
		if (currentStep === 1 || currentStep === 2) {
			!isDesktop && setIsSheetOpen(true);
		} else {
			!isDesktop && setIsSheetOpen(false);
		}
	}, [currentStep]);

	useEffect(() => {
		if (categories && !values.activeCategoryName) {
			setFieldValue('activeCategoryName', categories[0]?.name as string);
		}
	}, [categories, values.activeCategoryName, setFieldValue]);

	const DesktopView = (
		<div className={styles.container}>
			<h4 className={styles.title}>{t('Categories')}</h4>
			<GenderSelector formProps={formProps} gender={values.gender} setGender={onGenderSelect} />
			<CategoryList
				activeCategoryName={values.activeCategoryName}
				categories={categories}
				isLoading={isLoading}
				onCategorySelect={handleCategorySelect}
			/>
			<SelectedClothesBottomSheet formProps={formProps} isSheetOpen={isSheetOpen} />
		</div>
	);

	const MobileView = (
		<div className={styles.container_mobile}>
			<div className={styles.container}>
				<div
					className={styles.categoryFilter__metaContainer}
					onClick={() => setIsSheetOpen(true)}
					onKeyPress={() => setIsSheetOpen(true)}
				>
					<div>
						<h4 className={styles.title}>{t('Categories')}</h4>
						<div className={styles.categoryFilter__metaWrapper}>
							<p>{values.gender}</p>
							{isLoading ? <LoadingSkeleton height={16} width={100} /> : <p>{selectedCategory?.name}</p>}
						</div>
					</div>
					<ArrowRightIcon fill="var(--color-secondary-gray-700)" height={20} width={20} />
				</div>
				<BottomSheet
					headerContent={
						<div className={styles.categoryFilter__header}>
							<h4 className={styles.title}>{t('Categories')}</h4>
							<Button
								className={styles.categoryFilter__headerButton}
								icon={<CloseIcon height={20} width={20} />}
								size="extra_small"
								variant="clear"
								onClick={() => setIsSheetOpen(false)}
							/>
						</div>
					}
					initialSnap={0}
					isOpen={isSheetOpen}
					snapPoints={[700]}
					onClose={() => setIsSheetOpen(false)}
				>
					<div className={styles.categoryFilter__categoriesContainer}>
						<GenderSelector formProps={formProps} gender={values.gender} setGender={onGenderSelect} />
						<div className={styles.categoryFilter__categoriesWrapper}>
							<CategoryList
								activeCategoryName={values.activeCategoryName}
								categories={categories}
								isLoading={isLoading}
								onCategorySelect={handleCategorySelect}
							/>
						</div>
					</div>
				</BottomSheet>
			</div>
			<SelectedClothesBottomSheet formProps={formProps} isSheetOpen={isSheetOpen} />
		</div>
	);

	return <>{width > 1024 ? DesktopView : MobileView}</>;
};

export interface ICategoryFilterProps {
	formProps: FormikProps<TDemoFormValues>;
}

export default CategoryFilter;
