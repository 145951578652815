import { CATEGORY_ORDER } from '@constants';
import type { IClothingState } from '../../../services/implementations/ReduxService/slices/clothingSlice.slice';

export function sortClothingData(data: IClothingState['wardrobeItems']) {
	if (!data) return null;
	return Object.fromEntries(
		CATEGORY_ORDER.filter((category) => category in data).map((foundCategory) => [
			foundCategory,
			data?.[foundCategory],
		]),
	);
}
