import React from 'react';
import Skeleton, { SkeletonTheme } from 'react-loading-skeleton';
import 'react-loading-skeleton/dist/skeleton.css';
const LoadingSkeleton: React.FC<ILoadingSkeletonProps> = ({
	lines = 1,
	circle = false,
	baseColor = '#ebebeb',
	highlightColor = '#f5f5f5',
	width = '100%',
	height = '1rem',
	borderRadius = '0.25rem',
	inline = false,
	duration = 1.5,
	direction = 'ltr',
	enableAnimation = true,
}) => {
	return (
		<SkeletonTheme
			baseColor={baseColor}
			direction={direction as ILoadingSkeletonProps['direction']}
			highlightColor={highlightColor}
		>
			<Skeleton
				borderRadius={borderRadius}
				circle={circle}
				count={lines}
				duration={duration}
				enableAnimation={enableAnimation}
				height={height}
				inline={inline}
				width={width}
			/>
		</SkeletonTheme>
	);
};

export interface ILoadingSkeletonProps {
	/**
	 * Number of lines of skeletons to render.
	 * @default 1
	 */
	lines?: number;

	/**
	 * Makes the skeleton circular by setting border-radius to 50%.
	 * Use this for elements like avatars or profile images.
	 * @default false
	 */
	circle?: boolean;

	/**
	 * Base background color of the skeleton.
	 * Adjusts the theme color for the loading skeleton's base.
	 * @default '#ebebeb'
	 */
	baseColor?: string;

	/**
	 * Highlight color in the skeleton animation.
	 * This color appears in the shimmer effect of the loading skeleton.
	 * @default '#f5f5f5'
	 */
	highlightColor?: string;

	/**
	 * Width of each skeleton element.
	 * Accepts CSS-compatible values (e.g., '100%', '20px', etc.).
	 * @default '100%'
	 */
	width?: string | number;

	/**
	 * Height of each skeleton element.
	 * When `lines` is more than 1, it defines the height of each line.
	 * Accepts CSS-compatible values.
	 * @default '1rem'
	 */
	height?: string | number;

	/**
	 * Border radius of the skeleton.
	 * Can be used to make the skeleton appear as rounded boxes.
	 * @default '0.25rem'
	 */
	borderRadius?: string | number;

	/**
	 * Renders skeletons inline without line breaks.
	 * Useful when embedding skeletons within text or inline elements.
	 * @default false
	 */
	inline?: boolean;

	/**
	 * Duration of the skeleton animation in seconds.
	 * Controls the speed of the shimmering effect.
	 * @default 1.5
	 */
	duration?: number;

	/**
	 * Direction of the skeleton animation, left-to-right or right-to-left.
	 * Primarily for handling RTL (right-to-left) languages.
	 * @default 'ltr'
	 */
	direction?: 'ltr' | 'rtl';

	/**
	 * Enables or disables the animation.
	 * When false, the skeleton is static without shimmer.
	 * Useful for situations where you want a solid placeholder.
	 * @default true
	 */
	enableAnimation?: boolean;
}

export default LoadingSkeleton;
