import Tooltip from '../Tooltip';
import { QuestionStrokeIcon } from '../../icons';
import { useId } from 'react';
import { clsx } from '@utils';
import styles from './QuestionTooltip.module.css';
import type { ITooltipProps } from '../Tooltip';

const QuestionTooltip = ({
	className,
	content,
	place = 'top',
	Icon = QuestionStrokeIcon,
	color = 'var(--color-accent)',
}: IQuestionTooltipProps) => {
	const id = useId();

	return (
		<>
			<Icon
				className={clsx(styles.questionTooltip, className)}
				color={color}
				// data-tooltip-content={content}
				data-tooltip-id={id}
				data-tooltip-place={place}
			/>
			<Tooltip className={styles.tooltip__container} id={id} place={place}>
				{content}
			</Tooltip>
		</>
	);
};
interface IQuestionTooltipProps extends ITooltipProps {
	className?: string;
	/** Custom icon instead of default one  */
	Icon?: any;
	color?: string;
}
export default QuestionTooltip;
