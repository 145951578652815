import React from 'react';
import styles from '../TabBar.module.css';
import { Button } from '@ui-kit';
import { useTranslation } from 'react-i18next';
import { navItems } from '../utils';

const SmallTabBar = ({ menuOpen, setMenuOpen, activeNav, handleNavigation }: IMobileTabBarProps) => {
	const { t } = useTranslation();
	return (
		<>
			{/* eslint-disable-next-line jsx-a11y/click-events-have-key-events */}
			<div
				className={`${styles.overlay} ${menuOpen ? styles['overlay__open'] : ''}`}
				onClick={() => {
					setMenuOpen(false);
				}}
			/>
			<div className={`${styles.dropdownMenu} ${menuOpen ? styles['dropdownMenu__open'] : ''}`}>
				<div className={styles.dropdownMenu__items}>
					{navItems(t).map((item, index) => (
						<Button
							className={`${styles.bar__button} ${activeNav === item.hash ? styles.active : ''}`}
							key={index}
							title={item.title}
							titleClassName={styles.bar__title}
							variant={'link'}
							onClick={() => {
								setMenuOpen(false);
								handleNavigation(item.hash);
							}}
						/>
					))}
				</div>
				<div>
					<div className={styles.line} />
					{location?.pathname !== '/demo' && (
						<Button
							block={'fit'}
							title={t('Get Demo')}
							onClick={() => {
								// 	@todo add functional
							}}
						/>
					)}
				</div>
			</div>
		</>
	);
};

export interface IMobileTabBarProps {
	menuOpen: boolean;
	setMenuOpen: React.Dispatch<React.SetStateAction<boolean>>;
	handleNavigation: (hash: string) => void;
	activeNav: string;
}

export default SmallTabBar;
