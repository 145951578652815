export const RESPONSIVE_CAROUSEL_BREAKPOINTS = {
	576: {
		slidesPerView: 2,
		centeredSlides: true,
		spaceBetween: 12,
	},
	768: {
		slidesPerView: 2,
		centeredSlides: true,
		spaceBetween: 12,
	},
	1024: {
		slidesPerView: 2,
		centeredSlides: true,
	},
	1268: {
		slidesPerView: 2,
		centeredSlides: true,

		spaceBetween: 12,
	},
	1440: {
		centeredSlides: true,

		slidesPerView: 3,
	},
};
