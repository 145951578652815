import { createSlice } from '@reduxjs/toolkit';
import type { TAuth } from '@typings';

const initialState: TAuth = {
	status: undefined,
};

export const authSlice = createSlice({
	name: 'auth',
	initialState,
	reducers: {
		setAuthAuthorized: (state) => {
			state.status = 'authorized';
		},
		setAuthUnauthorized: (state) => {
			state.status = 'unauthorized';
		},
	},
});
