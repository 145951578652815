import { useService, useMutation, useNotification, useMe } from '@hooks';
import type { IBestOutfit } from '@typings';

export const useBestOutfitMutation = () => {
	const bestOutfitConvex = useService('BestOutfitConvex');
	const { showUnknownError } = useNotification();

	const { user } = useMe();

	return useMutation<unknown, Error, IBestOutfit>(
		['rateBestOutfit', user.id],
		async ({ rating, outfit_url, user_id, id, creationTime }) =>
			await bestOutfitConvex.addBestOutfit({
				user_id: user_id as string,
				rating: rating,
				outfit_url: outfit_url,
				id,
				creationTime,
			}),
		{
			onError: (error) => {
				showUnknownError(error);
			},
			cacheTime: 0,
		},
	);
};
