import type { ReactNode } from 'react';
import { useTranslation, useCallback, useMemo, useHandleBackButton, useDebouncedCallback, useScreenSize } from '@hooks';
import { ArrowLeftIcon, Button } from '@ui-kit';
import DiscardFormChangesModal from '@ui-modules/forms/components/DiscardFormChangesModal';
import { clsx, isEmpty, isUndefined } from '@utils';
import styles from './FormPageLayout.module.css';
import type { TMixpanelEvent } from '@typings';
import type { FormikErrors, FormikProps } from 'formik';

/** Form with header controllers and "keep editing" modal  */
// eslint-disable-next-line sonarjs/cognitive-complexity
function FormPageLayout<TValues extends Record<string, unknown>>({
	formProps,
	ignoreDirty,
	saveButtonTitle,
	isSaveButtonHidden = false,
	saveTrackingName,
	children,
	headerTitle,
	customLeftIcon,
	buttonBordered = true,
	customDisabled,
	onSuccessSubmit,
	onSaveFailed,
	onDiscarded,
	scrollEnabled = false,
	onSaveHandler,
	allowedNextPaths = [],
	customHeaderStyles = '',
	contentContainerStyle = '',
	submitLoading = false,
}: IFormPageLayoutProps<TValues>) {
	const { onBackButtonPress } = useHandleBackButton();
	const { t } = useTranslation();

	const { width } = useScreenSize();

	const finalSaveButtonTitle = saveButtonTitle ?? t('Save');
	const isDirtyOrInvalid = useMemo(
		() => (ignoreDirty ? !formProps.isValid : !formProps.dirty || !formProps.isValid),
		[ignoreDirty, formProps],
	);

	const onSave = useCallback(async () => {
		try {
			const validationErrors = await formProps.validateForm();
			if (!isEmpty(validationErrors)) {
				onSaveFailed?.(validationErrors);
				return;
			}
			await formProps.submitForm();
			onSuccessSubmit ? onSuccessSubmit() : onBackButtonPress();
		} catch (error) {
			console.info(`Save form error (Block:${headerTitle}):`, error);
		}
	}, [formProps]);
	const handleSave = useDebouncedCallback(onSaveHandler || onSave, 1000, { leading: true, trailing: false });

	return (
		<div className={clsx(styles.layout, scrollEnabled && styles.layout__scrollable, contentContainerStyle)}>
			{headerTitle ? (
				<div className={clsx(styles.layout__header, styles.layout__header_transparent, customHeaderStyles)}>
					<div className={styles.layout__headerButtonBlock}>
						<button className={styles.layout__headerButton} onClick={onBackButtonPress}>
							{customLeftIcon || (
								<ArrowLeftIcon
									className={styles.layout__headerBackIcon}
									fill={'var(--color-secondary-gray-700)'}
									height={33}
									width={33}
								/>
							)}
						</button>
					</div>
					<span className={styles.layout__headerTitle}>{headerTitle}</span>
					{width > 768 && (
						<div className={clsx(styles.layout__headerButtonBlock, styles.layout__headerButtonBlock_right)}>
							{isSaveButtonHidden ? null : (
								<Button
									disabled={!isUndefined(customDisabled) ? customDisabled : isDirtyOrInvalid}
									loading={submitLoading || formProps.isSubmitting}
									/** @TODO check whether the size is working fine */
									size={buttonBordered ? 'small' : 'medium'}
									title={finalSaveButtonTitle}
									trackingName={saveTrackingName}
									variant={buttonBordered ? 'secondary' : 'clear'}
									onClick={handleSave}
								/>
							)}
						</div>
					)}
				</div>
			) : null}
			<div className={clsx(styles.layout__content, scrollEnabled && styles.layout__contentScrollable)}>{children}</div>
			<DiscardFormChangesModal allowedNextPaths={allowedNextPaths} ignoreDirty={ignoreDirty} onDiscard={onDiscarded} />
			{width <= 768 && (
				<div
					className={clsx(
						styles.layout__headerButtonBlock,
						styles.layout__headerButtonBlock_right,
						styles.layout__headerResponsiveSaveButton,
					)}
				>
					{isSaveButtonHidden ? null : (
						<Button
							disabled={!isUndefined(customDisabled) ? customDisabled : isDirtyOrInvalid}
							loading={submitLoading || formProps.isSubmitting}
							/** @TODO check whether the size is working fine */
							size={buttonBordered ? 'small' : 'medium'}
							title={finalSaveButtonTitle}
							trackingName={saveTrackingName}
							variant={buttonBordered ? 'secondary' : 'clear'}
							onClick={handleSave}
						/>
					)}
				</div>
			)}
		</div>
	);
}

interface IFormPageLayoutProps<TValues> {
	/** form props for the header controllers */
	formProps: FormikProps<TValues>;
	/** Header save button title can be changed from default "save" */
	saveButtonTitle?: string;
	/** Decides whether save button should be hidden. Default 'false'. */
	isSaveButtonHidden?: boolean;
	/** Name of the event send to analytics on click. */
	saveTrackingName?: TMixpanelEvent;
	/** If header save button should not be disabled if form has not changed */
	ignoreDirty?: boolean;
	/** Subheader title */
	headerTitle?: string;
	/** Icon that will be displayed on the left button*/
	customLeftIcon?: ReactNode;
	/** ReactNode content of the form. */
	children: ReactNode;
	/** Is save button should be bordered */
	buttonBordered?: boolean;
	/** Disabled option that not connected to form validation */
	customDisabled?: boolean;
	/** If 'true' header layout shows without background color and shadow. Default 'false'. */
	isBackgroundTransparent?: boolean;
	/** Call function when save was failed because of form validation */
	onSaveFailed?: (errors: FormikErrors<TValues>) => void;
	/** Custom function that would be called on submit form success */
	onSuccessSubmit?: () => void;
	/** Custom function that would be called on onConfirm for Discard Modal
	 * for example if we use FormikPersist component we need to clear storage after discard */
	onDiscarded?: () => void;
	/** Define if scroll appears*/
	scrollEnabled?: boolean;
	/** List of routes to which you can navigate without triggering showing DiscardChanges modal dialog.
	 * 	It's important to add at least "current" path, because sometimes it may be redirected to itself with new state or props that should trigger redundant dialog
	 */
	allowedNextPaths: string[];
	/** On save click handler*/
	onSaveHandler?: () => void;
	/** Will apply custom styles to the header */
	customHeaderStyles?: string;
	/** Will apply custom styles to the container */
	contentContainerStyle?: string;
	/** Boolean to check form submitting status */
	submitLoading?: boolean;
}

export default FormPageLayout;
