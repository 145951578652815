import { AbstractReduxService } from '../../abstracts/AbstractReduxService';
import type { TReducerActions } from '../../abstracts/AbstractReduxService';
import type { IStorage } from '../../interfaces/Storage.interface';
// Slices:
import { clothingSlice } from './slices/clothingSlice.slice';
import { authSlice } from './slices/auth.slice';
import { meSlice } from './slices/me.slice';
import createIndexedDBStorage from 'redux-persist-indexeddb-storage';

/** Implementation of Redux Global Storage. */
export class ReduxService extends AbstractReduxService<TRootState, TAllActions> {
	static inject = ['LocalStorageService'] as const;
	constructor(readonly localStorage: IStorage) {
		const rootReducer = ReduxService.makeRootReducer(localStorage);
		super(rootReducer, {
			whitelist: [],
		});
	}

	// Configuration.
	static makeRootReducer = (localStorage: IStorage) =>
		AbstractReduxService.combineReducers({
			clothingSlice: AbstractReduxService.persistReducer(
				{
					key: 'clothingSlice',
					storage: createIndexedDBStorage(),
				},
				clothingSlice.reducer,
			),
			auth: AbstractReduxService.persistReducer(
				{
					key: 'authSlice',
					storage: localStorage,
				},
				authSlice.reducer,
			),
			me: AbstractReduxService.persistReducer(
				{
					key: 'meSlice',
					storage: localStorage,
				},
				meSlice.reducer,
			),
		});

	// Actions.
	clothingSlice = clothingSlice.actions;
	authSlice = authSlice.actions;
	meSlice = meSlice.actions;

	// Selectors.
}

export type TRootState = ReturnType<ReturnType<typeof ReduxService.makeRootReducer>>;

type TAllActions = TReducerActions<typeof clothingSlice.actions, 'clothingSlice'> &
	TReducerActions<typeof authSlice.actions, 'auth'> &
	TReducerActions<typeof meSlice.actions, 'me'>;
