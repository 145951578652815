import { useService } from '@services';
import type { TUser } from '@typings';

export const useMe = () => {
	const { useAppSelector } = useService('ReduxService');

	const user = useAppSelector((store) => store.me.user) as TUser;

	return {
		user,
		fullName: user?.fullName,
	};
};
