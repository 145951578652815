import React, { useState } from 'react';
import { joinUsSchema } from '@schemas';
import { Form, Formik } from 'formik';
import styles from '@ui-modules/home/components/JoinUs/JoinUs.module.css';
import { Button, Input } from '@ui-kit';
import { useTranslation } from 'react-i18next';
import { useEmailHandler } from '@hooks';

const JoinUsForm = () => {
	const { t } = useTranslation();

	const initialValues: TJoinUsForm = {
		name: '',
		email: '',
	};

	const [formValues, setFormValues] = useState<TJoinUsForm>(initialValues);

	const { mutateAsync: signInForEarlyAccess, isLoading } = useEmailHandler();

	const onSubmit = async (values: TJoinUsForm) => {
		await signInForEarlyAccess(values);
	};

	return (
		<Formik<TJoinUsForm>
			initialValues={initialValues}
			isInitialValid={false}
			validateOnChange={true}
			validationSchema={joinUsSchema(t)}
			onSubmit={onSubmit}
		>
			{({ errors, isValid, setFieldValue, values, resetForm }) => (
				<Form className={styles.signInForm__cta}>
					<Input
						autoComplete="off"
						errorMessage={errors.name}
						name="fullName"
						placeholder={t('Enter full name')}
						value={values.name}
						onChange={(event) => {
							setFieldValue('name', event.target.value);
							setFormValues((prevState) => ({ ...prevState, name: event.target.value }));
						}}
					/>
					<Input
						autoComplete="off"
						errorMessage={errors.email}
						name="fullName"
						placeholder={t('Enter your email')}
						value={values.email}
						onChange={(event) => {
							setFieldValue('email', event.target.value);
							setFormValues((prevState) => ({ ...prevState, email: event.target.value }));
						}}
					/>
					<Button
						disabled={!isValid || isLoading}
						title={t('Submit')}
						type="submit"
						onClick={() => {
							onSubmit(values);
							resetForm();
						}}
					/>
				</Form>
			)}
		</Formik>
	);
};

export type TJoinUsForm = {
	name: string;
	email: string;
};

export default JoinUsForm;
