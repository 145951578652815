// dependencies
import { ROUTES } from '@constants';
import { createBrowserRouter } from 'react-router-dom';
import { SecureRoutesWrapper } from './components/SecureRoutesWrapper';

import { HomePage, NotFoundPage } from './pages';

import { AppRouterContextProvider } from './AppRouterContext';
import { MainShell } from './shells/MainShell';
import { DemoPage } from './pages/ClothingSelectionPage';
import { WardrobePage } from './pages/WardrobePage';
import { BestOutfitsPage } from './pages/BestOutfitsPage';

const signingInFallbackElement = <>LOADING...</>;

export const appRouter = createBrowserRouter([
	{
		element: (
			<SecureRoutesWrapper signingInFallback={signingInFallbackElement}>
				{/*<InitializeUserWrapper>*/}
				{/*	<InitializeSessionWrapper>*/}
				<AppRouterContextProvider getAppRouter={() => appRouter}>
					<MainShell />
				</AppRouterContextProvider>
				{/*</InitializeSessionWrapper>*/}
				{/*</InitializeUserWrapper>*/}
			</SecureRoutesWrapper>
		),
		children: [
			{
				path: ROUTES.notFoundPage(),
				element: <NotFoundPage />,
			},
			{
				path: ROUTES.home(),
				element: <HomePage />,
			},

			{
				path: ROUTES.clothingSelection(),
				element: <DemoPage />,
			},
			{
				path: ROUTES.myWardrobe(),
				element: <WardrobePage />,
			},
			{
				path: ROUTES.bestOutfits(),
				element: <BestOutfitsPage />,
			},
		],
	},
]);
