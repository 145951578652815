import React, { useState } from 'react';
import { useScreenSize, useTranslation } from '@hooks';
import { clsx } from '@utils';
import { Button, Modal } from '@ui-kit';
import { useClothingActions } from '@ui-modules/demo/hooks/useClothingActions';
import { useToggle } from 'react-use';

import styles from './GenderSelector.module.css';
import type { TGender } from '@typings';
import type { FormikProps } from 'formik';
import type { TDemoFormValues } from '../../../../app/pages/ClothingSelectionPage/ClothingSelectionPage';

export interface IGenderSelectorProps {
	setGender: (gender: TGender) => void;
	gender: TGender;
	formProps: FormikProps<TDemoFormValues>;
}

const GenderSelector: React.FC<IGenderSelectorProps> = ({ gender, setGender, formProps }) => {
	const { t } = useTranslation();
	const { width } = useScreenSize();
	const { setFieldValue, values } = formProps;
	const { setSelectedClothes } = useClothingActions();

	const [showDialog, toggleShowDialog] = useToggle(false);
	const [pendingGender, setPendingGender] = useState<TGender | null>(null);

	const onGenderToggle = (selectedGender: TGender) => {
		if (values.selectedClothes?.length && selectedGender !== gender) {
			setPendingGender(selectedGender); // Set pending gender
			toggleShowDialog();
		} else {
			setGender(selectedGender);
		}
	};

	const onDiscard = () => {
		if (pendingGender) {
			setGender(pendingGender);
		}
		setSelectedClothes([]);
		setFieldValue('selectedClothes', []);
		setPendingGender(null);
		toggleShowDialog();
	};

	return (
		<>
			<div className={styles.gender__container}>
				<Button
					block={width > 1024 ? 'inline' : 'fit'}
					className={clsx(styles.pressable_feminine, gender === 'masculine' && styles.pressable_disabled)}
					size="small"
					title={t('Feminine')}
					titleClassName={clsx(styles.pressable__title, gender === 'masculine' && styles.pressable__title_disabled)}
					onClick={() => onGenderToggle('feminine')}
				/>
				<Button
					block={width > 1024 ? 'inline' : 'fit'}
					className={clsx(styles.pressable_masculine, gender === 'feminine' && styles.pressable_disabled)}
					size="small"
					title={t('Masculine')}
					titleClassName={clsx(styles.pressable__title, gender === 'feminine' && styles.pressable__title_disabled)}
					onClick={() => onGenderToggle('masculine')}
				/>
			</div>

			{/* Confirmation Modal */}
			<Modal
				cancelTitle={t('Yes, switch')}
				confirmTitle={t('No, stay with my selection')}
				subTitle={t(
					'Switching the gender preference will clear your current selections. Do you still want to proceed?',
				)}
				title={t('Switching Styles?')}
				variant="medium"
				visible={showDialog}
				onCancel={onDiscard}
				onConfirm={() => {
					setPendingGender(null);
					toggleShowDialog();
				}}
			/>
		</>
	);
};

export default GenderSelector;
