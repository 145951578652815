import dayjs from 'dayjs';
import duration from 'dayjs/plugin/duration';
import relativeTime from 'dayjs/plugin/relativeTime';
import utc from 'dayjs/plugin/utc';
import timezone from 'dayjs/plugin/timezone';
import advancedFormat from 'dayjs/plugin/advancedFormat';
import updateLocale from 'dayjs/plugin/updateLocale';
import weekOfYear from 'dayjs/plugin/weekOfYear';
import isBetween from 'dayjs/plugin/isBetween';
import localeDate from 'dayjs/plugin/localeData';
import customParseFormat from 'dayjs/plugin/customParseFormat';
import minMax from 'dayjs/plugin/minMax';
import quarterOfYear from 'dayjs/plugin/quarterOfYear';

dayjs.extend(customParseFormat);
dayjs.extend(duration);
dayjs.extend(weekOfYear);
dayjs.extend(utc);
dayjs.extend(timezone);
dayjs.extend(advancedFormat);
dayjs.extend(isBetween);
dayjs.extend(localeDate);
dayjs.extend(minMax);
dayjs.extend(quarterOfYear);
dayjs.extend(relativeTime, {
	thresholds: [
		{ l: 's', r: 1 },
		{ l: 'ss', r: 59, d: 'second' },
		{ l: 'm', r: 1 },
		{ l: 'mm', r: 59, d: 'minute' },
		{ l: 'h', r: 1 },
		{ l: 'hh', r: 23, d: 'hour' },
		{ l: 'd', r: 1 },
		{ l: 'dd', r: 6, d: 'day' },
		{ l: 'w', r: 1 },
		{ l: 'ww', r: 4, d: 'week' },
		{ l: 'M', r: 1 },
		{ l: 'MM', r: 11, d: 'month' },
		{ l: 'y', r: 1 },
		{ l: 'yy', d: 'year' },
	],
});
dayjs.extend(updateLocale);
dayjs.updateLocale('en', {
	relativeTime: {
		future: 'in %s',
		past: '%s',
		s: 'Just now',
		ss: 'Just now',
		m: '%d' + 'm',
		mm: '%d' + 'm',
		h: '%d' + 'h',
		hh: '%d' + 'h',
		d: '%d' + 'd',
		dd: '%d' + 'd',
		w: '%d' + 'w',
		ww: '%d' + 'w',
		M: '%d' + 'mo',
		MM: '%d' + 'mo',
		y: '%d' + 'y',
		yy: '%d' + 'y',
	},
});

export default dayjs;
