import React, { useState } from 'react';
import ImageUploading from 'react-images-uploading';
import { CameraAddIcon } from '@ui-kit';
import styles from './ImageUploader.module.css';

import type { ImageType } from 'react-images-uploading';
import { useWindowSize } from 'react-use';
import { renameImages } from '@ui-modules/demo/utils/renameImages';
import ActivityIndicator from '../../../../ui-kit/components/ActivityIndicator';
import { convertHEICToJPG } from '@ui-modules/demo/components/ImageUploader/utils';

const ImageUploader = ({
	maxNumber,
	images,
	onImagesChange,
	imageUploaderTitle,
	separator = false,
	renderImage,
	isLoading,
}: IImageUploaderProps) => {
	const { width } = useWindowSize();
	const [isConverterLoading, setIsConverterLoading] = useState(false);

	const onChange = async (imageList: ImageType[], freshListOfImagesIndexes: number[] | undefined) => {
		setIsConverterLoading(true);
		const convertedImages = await convertHEICToJPG(imageList);
		setIsConverterLoading(false);

		const renamedImages = renameImages(convertedImages);
		onImagesChange(renamedImages, freshListOfImagesIndexes);
	};

	return (
		<ImageUploading
			// @todo move to the constant ts
			acceptType={['jpg', 'png', 'jpeg', 'heic']}
			dataURLKey="image_url"
			maxNumber={maxNumber}
			multiple
			value={images}
			onChange={onChange}
		>
			{({ imageList, onImageUpload, dragProps }) => {
				return (
					<div className={styles.imageUploader__wrapper}>
						<div
							className={styles.imageUploader__uploadWrapper}
							onClick={onImageUpload}
							onKeyPress={onImageUpload}
							{...dragProps}
						>
							<CameraAddIcon width={40} />
							<p>{imageUploaderTitle}</p>
						</div>
						{separator && width > 768 && <div className={styles.imageUploader__separator}></div>}
						{/* Image List */}
						<div className={styles.imageUploader__imageList}>
							{(isLoading || isConverterLoading) && (
								<div className={styles.imageUploader__loadingIndicator}>
									<ActivityIndicator color="var(--color-secondary-gray-600)" />
								</div>
							)}
							{imageList?.map((image, index) => renderImage({ image, index }))}
						</div>
					</div>
				);
			}}
		</ImageUploading>
	);
};

export interface IImageUploaderProps {
	maxNumber?: number;
	images: ImageType[];
	onImagesChange: (images: ImageType[], freshListOfImagesIndexes: number[] | undefined) => void;
	imageUploaderTitle: string;
	separator?: boolean;
	renderImage: ({ image, index }: { image: ImageType; index: number }) => React.ReactNode;
	isLoading: boolean;
}

export default ImageUploader;
