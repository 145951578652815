import { useService, useQuery, useMe } from '@hooks';
import type { UseQueryOptions } from '@tanstack/react-query';
import type { IBestOutfit } from '@typings';

export const useGetBestOutfitsConvexQuery = <T = TUseGetBestOutfitsConvexQueryData>(
	queryOptions?: UseQueryOptions<TUseGetBestOutfitsConvexQueryData, Error, T>,
) => {
	const bestOutfitConvex = useService('BestOutfitConvex');

	const { user } = useMe();

	return useQuery<TUseGetBestOutfitsConvexQueryData, Error, T>(
		['getBestOutfitsConvex', user.id],
		() =>
			bestOutfitConvex.getBestOutfits({
				user_id: user.id as string,
			}),
		{
			...queryOptions,
			cacheTime: 0,
			staleTime: 0,
		},
	);
};

type TUseGetBestOutfitsConvexQueryData = IBestOutfit[];
