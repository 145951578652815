import { useScreenSize, useService, useTranslation } from '@hooks';
import styles from './Features.module.css';
import { AdviceIcon, Button, DoodlesIcon, MagicIcon, RecommendationIcon, WardrobeIcon } from '@ui-kit';
import FeaturesCard from '@ui-modules/home/components/FeaturesCard';
import { ROUTES } from '@constants';
import { useNavigate } from 'react-router-dom';

// @todo change this to make it work this helps now with cases such as breaking ui icons create a task
const UI_BREAKING_SCREEN_WIDTH = 1293;
const STRUCTURE_CHANGE_SCREEN_WIDTH = 1200;

const Features = () => {
	const { t } = useTranslation();
	const screenSize = useScreenSize();
	const navigate = useNavigate();
	const analytics = useService('AnalyticsService');

	const onDemoClick = () => {
		navigate(ROUTES.clothingSelection());
	};

	const onGetAccessClick = () => {
		const targetElement = document.querySelector('#join-us');
		if (targetElement) {
			targetElement.scrollIntoView({ behavior: 'smooth' });
		}
		navigate(ROUTES.joinUs());
	};

	return (
		<section className={styles.features__container} id="features">
			<div className={styles.features__firstSection}>
				{screenSize.width > STRUCTURE_CHANGE_SCREEN_WIDTH && (
					<img alt="features" className={styles.features__image} src="/assets/images/features_section.jpeg" />
				)}
				<div>
					<div className={styles.features__titleWrapper}>
						<h2 className={styles.features__title}>
							{t('Transform your wardrobe with')}
							<span className={styles.features__titleHighlight}> {t('smart features')}</span>
						</h2>
						{screenSize.width > UI_BREAKING_SCREEN_WIDTH && (
							<DoodlesIcon className={styles.features__doodleIcon} fill={'#FCCA9B'} />
						)}
					</div>
					<p className={styles.features__description}>
						{t(
							'Discover how our advanced features can elevate your style—daily outfit suggestions, seamless wardrobe organization, and expert as your smart shopping buddy, all designed to help you look your best every day.',
						)}
					</p>
					{screenSize.width <= STRUCTURE_CHANGE_SCREEN_WIDTH && (
						<img alt="features" className={styles.features__image} src="/assets/images/features_section.jpeg" />
					)}
					<div className={styles.features__ctaWrapper}>
						<Button
							icon={<MagicIcon fill="#141B24" />}
							size={screenSize.width < 465 ? 'medium' : 'large'}
							title={t('Try Demo Now')}
							onClick={onDemoClick}
						/>
						<Button
							size={screenSize.width < 465 ? 'medium' : 'large'}
							title={t('Get access')}
							variant="secondary"
							onClick={onGetAccessClick}
						/>
					</div>
				</div>
			</div>
			<div className={styles.features__cardsWrapper}>
				<FeaturesCard icon={<RecommendationIcon fill="#FFFEFE" />} title={t('Daily Outfit Ideas')} />
				<FeaturesCard icon={<WardrobeIcon fill="#FFFEFE" />} title={t('Wardrobe Manager')} />
				<FeaturesCard icon={<AdviceIcon fill="#FFFEFE" />} title={t('Smart Purchase Assistant')} />
			</div>
		</section>
	);
};

export default Features;
