import { AbstractAxiosService } from '../abstracts/AbstractAxiosService';
import { httpCodes } from '../abstracts/AbstractAxiosService/httpCodes';
import type { AxiosError, AxiosRequestConfig, AxiosResponse } from 'axios';
import type { IApiConfig, IAppConfig, IEnvironmentConfig } from '../interfaces/AppConfig.interface';
import type { TAxiosEvents } from '../interfaces/AxiosService.interface';
import type { IDevLogger } from '../interfaces/DevLogger.interface';
import type { IRouterService } from '../interfaces/RouterService.interface';
import type { ReduxService } from './ReduxService';

/** Wrapper for axios provides basic interceptors and error handles. */
export class AxiosService extends AbstractAxiosService<TAxiosEvents> {
	static inject = ['AppConfigService', 'logger', 'SentryService', 'RouterService', 'ReduxService'] as const;
	constructor(
		apiConfig: IAppConfig & IApiConfig & IEnvironmentConfig,
		logger: IDevLogger,
		private readonly router: IRouterService,
		private readonly redux: ReduxService,
	) {
		super(
			{
				API_BASE_URL: apiConfig.API_BASE_URL,
				// apiConfig.APP_ENV === 'dev' && apiConfig.NODE_ENV === 'development' ? '/api' : 'apiConfig.VITE_API_BASE_URL',
			},
			{
				headers: {
					'X-Client': 'native-app',
					'Content-Type': 'application/json',
					Accept: 'application/ld+json',
				},
				timeout: 1600000,
			},
		);
		this.logger = logger.child('AxiosService');
	}

	private logger: IDevLogger;

	// protected getBearerToken(requestConfig: AxiosRequestConfig) {
	// 	const urlsToSkipSendingBearerToken = ['/auth/refresh', '/auth/exchange-login'];
	// 	if (urlsToSkipSendingBearerToken.includes(String(requestConfig.url))) {
	// 		return undefined; // don't send token to prevent Unauthorized error, refresh should be without token
	// 	}
	// }

	protected async resetBearerToken() {
		// this.redux.store.dispatch(this.redux.auth.resetUser());
	}

	protected onUnauthorized() {
		/* 	Do nothing here.
			We delegate handling of 401 error to the higher level ReactQueryService
			which implements retry logic trying to refresh tokens without immediate signing out.
		*/
	}

	protected onError(error: AxiosError) {
		// this.bugTracker.captureException(error);
		this.logger.error(error);

		const request = error?.response?.config as AxiosRequestConfig | undefined;
		const response = error?.response as AxiosResponse;

		// this.analytics.trackEvent('http_request_failed', {
		// 	'#url': String(request?.url),
		// 	'#method': String(request?.method),
		// 	'#responseCode': response?.status ?? 0,
		// 	'#accessToken': request?.headers?.Authorization?.toString?.(),
		// 	'#responseData': response?.data,
		// });

		// Kick former/inactive/blocked user out.
		if (error?.response?.status === httpCodes.FORBIDDEN) {
			const errorDescription = (error.response?.data as any)?.['hydra:description'] as string;
			if (
				errorDescription === 'For Former or Inactive Member Access Denied.' ||
				errorDescription === 'This account is blocked' ||
				errorDescription.startsWith('Credentials was reset')
			) {
				this.router.handleUserUnauthorized();
			}
		}
	}

	protected onNetworkError(url: string) {
		this.logger.log('Network error', url);
	}

	protected onRequestStarted(url: string, data: unknown) {
		this.logger.debug('request started', url);
	}

	protected onRequestFinished(request: AxiosRequestConfig, response?: AxiosResponse): void {
		this.logger.debug('request finished', request?.url, response?.status);

		// this.analytics.trackEvent('http_request_completed', {
		// 	'#url': String(request?.url),
		// 	'#method': String(request?.method),
		// 	'#responseCode': response?.status ?? 0,
		// });
	}
}
