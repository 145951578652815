import { createSlice } from '@reduxjs/toolkit';
import type { PayloadAction } from '@reduxjs/toolkit';
import type { TUser } from '@typings';
import { v4 as uuidv4 } from 'uuid';

export interface IMeState {
	user: TUser | null;
}

const initialState: IMeState = {
	user: {
		// @TODO now we artificially set the user id here for the sake of the demo
		id: uuidv4(),
	},
};

export const meSlice = createSlice({
	name: 'me',
	initialState,
	reducers: {
		saveAccount: (state, { payload: user }: PayloadAction<TUser>) => {
			state.user = user;
		},
		removeAccount: (state) => {
			state.user = initialState.user;
		},
	},
});
