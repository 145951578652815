import genderStyles from '@ui-modules/demo/components/GenderSelector/GenderSelector.module.css';
import categoryRowStyles from '@ui-modules/demo/components/CategoryRow/CategoryRow.module.css';
import clothingItemStyles from '@ui-modules/demo/components/ClothingItemCard/ClothingItemCard.module.css';
import categoryFilterStyles from '@ui-modules/demo/components/CategoryFilter/CategoryFilter.module.css';
import imageUploaderStyles from '@ui-modules/demo/components/ImageUploader/ImageUploader.module.css';
import type { ProviderProps } from '@reactour/tour';

export const getCategoriesTourSteps = (t: (key: string) => string, isDesktop: boolean): ProviderProps['steps'] => {
	const steps: ProviderProps['steps'] = [
		!isDesktop
			? {
					selector: `.${categoryFilterStyles.categoryFilter__metaContainer}`,
					content: t('Click here to open categories.'),
					position: 'right',
			  }
			: undefined,
		{
			selector: `.${genderStyles.gender__container}`,
			content: t('Select the gender that best matches the type of clothing you prefer to wear.'),
			position: isDesktop ? 'right' : 'center',
		},

		{
			selector: `.${categoryRowStyles.row}`,
			content: t('Choose the items from the categories that best match what you own.'),
			position: 'right',
		},

		{
			selector: `.${clothingItemStyles.clothingItemCard__item}`,
			content: t('Click here to add an item to your wardrobe.'),
			position: 'right',
		},
		{
			selector: `.${imageUploaderStyles.imageUploader__uploadWrapper}`,
			content: t("Optionally, take a photo of your item on a plain surface, and we'll handle the rest."),
			position: 'right',
		},
		{
			selector: `.${categoryFilterStyles.wardrobe__toWardrobe}`,
			content: t('After adding a few items, click here to view your wardrobe.'),
			position: 'right',
		},
	].filter((item) => !!item) as ProviderProps['steps'];

	return steps;
};
