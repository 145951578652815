import Bottle from 'bottlejs';
import { AppConfigService } from '../implementations/AppConfigService';
import { AxiosService } from '../implementations/AxiosService';
import { I18nService } from '../implementations/I18nService';
import { LocalStorageService } from '../implementations/LocalStorageService';
import { LoggerService } from '../implementations/LoggerService';
import { ReactQueryService } from '../implementations/ReactQueryService';
import { ReduxService } from '../implementations/ReduxService';
import { RouterService } from '../implementations/RouterService';
import { ApiService } from '../implementations/ApiService';
import { ToastService } from '../implementations/ToastService';
import { MixPanelService } from '../implementations/MixPanelService';
import { SentryService } from '../implementations/SentryService';
import { ClothingService } from '../implementations/ClothingService';
import { MediaService } from '../implementations/MediaService';
import { EmailService } from '../implementations/EmailService';
import { ConvexClientService } from '../implementations/ConvexClientService';
import { AuthService } from '../implementations/AuthService';
import { BestOutfitConvex } from '../implementations/BestOutfitConvex';

// Describe all DI-container services here.
const services = {
	AppConfigService, // must be first.
	logger: LoggerService, // must be second.
	LocalStorageService,
	I18nService,
	RouterService,
	ReactQueryService,
	AuthService,
	ReduxService,
	ToastService,
	AxiosService,
	ApiService,
	SentryService,
	AnalyticsService: MixPanelService,
	ClothingService,
	MediaService,
	EmailService,
	BestOutfitConvex,
	ConvexClientService,
};

type TServices = typeof services;
export type TServiceInstances = TInstances<TServices>;

export class ServicesContainer {
	private services: TServices = services;
	private bottle: Bottle;

	constructor() {
		const bottle = new Bottle();

		for (const depName in this.services) {
			const serviceConstructor = this.services[depName as keyof TServices];
			bottle.service(depName, serviceConstructor as never, ...(serviceConstructor.inject ?? []));
		}
		this.bottle = bottle;
	}

	resolve<T extends keyof typeof services>(depName: T) {
		return this.bottle.container[depName] as InstanceType<TServices[T]>;
	}
}

type TInstances<C extends Record<string, abstract new (...args: any) => any>> = {
	[K in keyof C]: InstanceType<C[K]>;
};
