import ActivityIndicator from '../ActivityIndicator';
import styles from './Button.module.css';
import { clsx } from '@utils';
import type { MouseEventHandler, ReactNode } from 'react';
import { useContext } from 'react';
import { UikitContext } from '@ui-kit';

/** Clickable button.
 *  Can "fit" the whole width or be "inline"
 */
const Button = ({
	title,
	disabled = false,
	loading = false,
	size = 'medium',
	block = 'inline',
	variant = 'primary',
	icon: IconComponent,
	iconPosition = 'left',
	trackingName,
	type = 'button',
	testID,
	onClick,
	className,
	titleClassName,
	badge: Badge,
}: IButtonProps) => {
	const { trackClickEvent } = useContext(UikitContext);

	const handleClick: MouseEventHandler<HTMLButtonElement> = (event) => {
		event.stopPropagation();
		if (onClick) onClick(event);
		if (trackingName) {
			trackClickEvent(trackingName);
		}
	};

	return (
		<button
			aria-label={title}
			className={clsx(styles.button, className)}
			data-block={block}
			data-size={size}
			data-testid={testID}
			data-variant={variant}
			disabled={disabled || loading}
			type={type}
			onClick={handleClick}
		>
			{IconComponent && !loading && iconPosition === 'left' ? (
				<span className={styles.button__icon}>{IconComponent}</span>
			) : null}
			{title ? <span className={clsx(styles.button__title, titleClassName)}>{title}</span> : null}
			{IconComponent && !loading && iconPosition === 'right' ? (
				<span className={styles.button__icon}>{IconComponent}</span>
			) : null}
			{Badge && <span className={styles.button__icon}>{Badge}</span>}
			<ActivityIndicator
				className={styles.button__activityIndicator}
				color={'var(--color-secondary-gray-400)'}
				hidden={!loading}
				size="small"
			/>
		</button>
	);
};

export type TButtonVariant = 'primary' | 'secondary' | 'tertiary' | 'link' | 'clear';
export type TButtonSize = 'large' | 'extra_small' | 'small' | 'medium';
export interface IButtonProps {
	/** Button label. */
	title?: string;
	/** Should it be able to be clicked. Default=false. */
	disabled?: boolean;
	/** Should show spinner instead of content. Default=false. */
	loading?: boolean;
	/** Size of the button. Default=medium */
	size?: TButtonSize;
	/** Should button "fit" the whole width or be "inline". */
	block?: 'fit' | 'inline';
	/** Design of the button. */
	variant?: TButtonVariant;
	/** React/HTML icon component (likely svg). If icon passed the "iconPosition" property is required */
	icon?: ReactNode;
	/** Custom Badge which can be rendered next to icon's right icon */
	badge?: ReactNode;
	/** Position of icon component. Can be "left" or "right" */
	iconPosition?: 'left' | 'right';
	/** Name of the event sent to trackClickEvent function of UikitContext to pass it to the analytics service. */
	/** @todo add tracking event typing here @author Shant Issa */
	trackingName?: any;
	/** On click handler. Will not be called if button disabled or in loading. */
	onClick?: MouseEventHandler<HTMLButtonElement>;
	/** Class name of the button**/
	className?: string;
	/** Test ID to use with Jest. */
	testID?: string;
	/** Class name of the button title**/
	titleClassName?: string;
	/** Type of the button **/
	type?: 'button' | 'submit' | 'reset' | undefined;
}

export default Button;
