import type { ReactElement, ReactNode } from 'react';
import React from 'react';
import { clsx } from '@utils';
import styles from './FullScreenPageLayout.module.css';
import { ArrowLeftIcon, Button } from '@ui-kit';
import { useNavigate } from 'react-router-dom';
import { useTranslation } from 'react-i18next';
import { useScreenSize } from '@hooks';

const FullScreenPageLayout = ({
	headerTitle,
	children,
	rightButtonText,
	rightButtonDisabled = false,
	isFixed = false,
	onRightButtonPress,
	rightButton,
	backButtonEnabled = false,
}: IFullScreenPageLayoutProps) => {
	const navigate = useNavigate();
	const { t } = useTranslation();
	const { width } = useScreenSize();

	return (
		<div className={clsx(styles.layout, isFixed && styles.layout_fixed)}>
			{headerTitle ? (
				<div className={styles.layout__header}>
					{backButtonEnabled && (
						<Button
							className={styles.layout__headerBackIcon}
							icon={<ArrowLeftIcon fill={'#3F4647'} height={24} width={24} />}
							size="small"
							title={width < 425 ? '' : t('Back')}
							variant="clear"
							onClick={() => {
								navigate(-1);
							}}
						/>
					)}
					<span className={styles.layout__headerTitle}>{headerTitle}</span>
					<div className={clsx(styles.layout__headerButtonBlock, styles.layout__headerButtonBlock_right)}>
						{!rightButton ? (
							rightButtonText && onRightButtonPress ? (
								<button
									className={styles.layout__headerButton}
									disabled={rightButtonDisabled}
									onClick={onRightButtonPress}
								>
									<span className={styles.layout__headerLabel}>{rightButtonText}</span>
								</button>
							) : null
						) : (
							rightButton
						)}
					</div>
				</div>
			) : null}
			<div className={clsx(styles.layout__content, isFixed && styles.layout__content_fixed)}>{children}</div>
		</div>
	);
};

export interface IFullScreenPageLayoutProps {
	headerTitle?: string;
	children: ReactElement;
	rightButton?: ReactNode;
	rightButtonText?: string;
	rightButtonDisabled?: boolean;
	onRightButtonPress?: () => void;
	/** Set to "true" if you want page to not grow out of the visible part and prevent appearing of the scrollbar. Default "false". */
	isFixed?: boolean;
	backButtonEnabled?: boolean;
}

export default FullScreenPageLayout;
