import { useService, useMutation, useNotification, useMe } from '@hooks';

export const useDeleteBestOutfitConvexMutation = () => {
	const bestOutfitConvex = useService('BestOutfitConvex');
	const { showUnknownError } = useNotification();
	const reactQuery = useService('ReactQueryService');

	const { user } = useMe();

	return useMutation<unknown, Error, { id: string }>(
		['deleteBestOutfitConvex'],
		async ({ id }) => await bestOutfitConvex.deleteOutfit({ user_id: user.id as string, id }),
		{
			onError: (error) => {
				showUnknownError(error);
			},
			onSuccess: () => {
				reactQuery.queryClient.refetchQueries(['getBestOutfitsConvex']);
			},
		},
	);
};
