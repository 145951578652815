import { useScreenSize, useTranslation } from '@hooks';
import styles from './Hero.module.css';
import { Button, CardSwiper, MagicIcon } from '@ui-kit';
import { useNavigate } from 'react-router-dom';
import { ROUTES } from '@constants';
import Image from 'rc-image';
import { heroBlockSwipeImages } from '@ui-modules/home/components/Hero/utils';
import React from 'react';
import type { Variants } from 'framer-motion';
import { motion } from 'framer-motion';

const Hero = () => {
	const { t } = useTranslation();
	const screenSize = useScreenSize();
	const navigate = useNavigate();
	// @todo uncomment when needed
	// const [showDialog, toggleShowDialog] = useToggle(false);

	const onGetEarlyAccessClick = () => {
		const targetElement = document.querySelector('#join-us');
		if (targetElement) {
			targetElement.scrollIntoView({ behavior: 'smooth' });
		}
		navigate(ROUTES.joinUs());
	};

	const buttonVariants: Variants = {
		animate: {
			scale: 1,
			y: 0,
			opacity: 1,
			rotate: 0,
			transition: {
				type: 'spring',
				stiffness: 120,
				damping: 10,
				delay: 0.3,
			},
		},
		hover: {
			y: -8,
			scale: 1.05,
			rotate: 2,
			boxShadow: '0 12px 24px rgba(0,0,0,0.15)',
			background: 'linear-gradient(45deg, #FF6B6B, #FF8E53)',
			transition: {
				duration: 0.3,
				ease: 'easeInOut',
			},
		},
		tap: {
			scale: 0.95,
			rotate: -1,
		},
	};

	// @todo uncomment when needed
	// const onDiscard = () => {
	// 	toggleShowDialog();
	// };
	//
	// const { status } = useAuthorizationStatus();
	// const { onError, signIn } = useGoogleAuth({
	// 	onSuccess: () => {
	// 		toggleShowDialog();
	// 		navigate(ROUTES.clothingSelection());
	// 	},
	// });

	const onDemoClick = () => {
		// if (status !== 'authorized') {
		// 	toggleShowDialog();
		// } else if (status === 'authorized') {
		navigate(ROUTES.clothingSelection());
		// }
	};

	const cardSwiper = (
		<div className={styles.hero__image}>
			<CardSwiper cards={heroBlockSwipeImages} />
		</div>
	);

	return (
		<section className={styles.hero}>
			<div className={styles.hero__content}>
				<h1 className={styles.hero__title}>
					{t('Your AI-powered ')} <span className={styles['hero__title_highlight']}>{t('wardrobe assistant')} </span>
					{t('your best looks every day')}
					<span className={styles['hero__subtitle_highlight']}> {t('find, match, and shine')}</span>
				</h1>
				{screenSize.width <= 1024 && cardSwiper}
				<p className={styles.hero__description}>
					{t(
						'Simplify your style routine with AI-powered outfit recommendations. Organize your wardrobe, pair new purchases with confidence, and enjoy the satisfaction of perfectly matched looks for any occasion.',
					)}
				</p>
				{screenSize.width > 1024 && (
					<div className={styles.hero__actions}>
						<motion.div
							animate="animate"
							style={{
								background: 'linear-gradient(45deg, #FF8E53, #FF6B6B)',
								borderRadius: '12px',
								display: 'inline-block',
							}}
							variants={buttonVariants}
							whileHover="hover"
							whileTap="tap"
						>
							<Button
								block={'inline'}
								icon={<MagicIcon fill={'#141B24'} />}
								size="large"
								title={t('Try Demo Now')}
								onClick={onDemoClick}
							/>
						</motion.div>
						<Button
							block={'inline'}
							size="large"
							title={t('Join Now!')}
							variant={'secondary'}
							onClick={onGetEarlyAccessClick}
						/>
					</div>
				)}
			</div>
			{screenSize.width > 1024 && (
				<div className={styles.hero__image}>
					<Image
						alt="Hero Grid"
						className={styles.hero__image}
						placeholder
						preview={false}
						src={'/assets/images/hero_grid.webp'}
					/>
				</div>
			)}
			{screenSize.width <= 1024 && (
				<div className={styles.hero__actions}>
					<motion.div
						animate="animate"
						style={{
							background: 'linear-gradient(45deg, #FF8E53, #FF6B6B)',
							borderRadius: '12px',
							display: 'inline-block',
						}}
						variants={buttonVariants}
						whileHover="hover"
						whileTap="tap"
					>
						<Button
							block={'fit'}
							icon={<MagicIcon fill={'#141B24'} />}
							size="large"
							title={t('Try Demo Now')}
							onClick={onDemoClick}
						/>
					</motion.div>
					<Button
						block={'fit'}
						size="large"
						title={t('Join Now!')}
						variant={'secondary'}
						onClick={onGetEarlyAccessClick}
					/>
				</div>
			)}
			{/*@todo uncomment when needed*/}
			{/*<Modal*/}
			{/*	modalContent={*/}
			{/*		<div className={styles.hero__signInModalCTA}>*/}
			{/*			<GoogleSignInButton shape={'square'} signIn={signIn} text={'signin_with'} onError={onError} />*/}
			{/*			<Button*/}
			{/*				block={'inline'}*/}
			{/*				className={styles.hero__signInModalCTAButton}*/}
			{/*				size="extra_small"*/}
			{/*				title={t('Continue as Guest')}*/}
			{/*				variant={'secondary'}*/}
			{/*				onClick={() => {*/}
			{/*					navigate(ROUTES.clothingSelection());*/}
			{/*				}}*/}
			{/*			/>*/}
			{/*		</div>*/}
			{/*	}*/}
			{/*	subTitle={t(*/}
			{/*		'Sign in with Google to enjoy hassle-free access to all features. Otherwise, continue as a guest—but you’ll lose out on exclusive benefits.',*/}
			{/*	)}*/}
			{/*	title={t('Enhance Your Experience with One Click')}*/}
			{/*	variant="medium"*/}
			{/*	visible={showDialog}*/}
			{/*	onCancel={onDiscard}*/}
			{/*	onConfirm={toggleShowDialog}*/}
			{/*/>*/}
		</section>
	);
};

export default Hero;
