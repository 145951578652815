import React, { useRef, useState, useEffect } from 'react';
import styles from './GroupedWardrobeSections.module.css';
import Header from './Header';
import ClothingItemCard from '@ui-modules/demo/components/ClothingItemCard';
import { useClothingActions } from '@ui-modules/demo/hooks/useClothingActions';
import type { ICategory } from '@typings';
import type { IClothingState } from '../../../../services/implementations/ReduxService/slices/clothingSlice.slice';

const ITEMS_PER_SCROLL = 3;

const GroupedWardrobeSections = () => {
	const { resetWardrobeItemsSelection, wardrobeItems, toggleWardrobeItemSelection } = useClothingActions();

	const wardrobeSections = Object.entries((wardrobeItems as IClothingState['wardrobeItems']) || {});

	const scrollRefs = useRef<HTMLElement[]>([]);
	const [scrollStates, setScrollStates] = useState(
		wardrobeSections.map(() => ({ canScrollLeft: false, canScrollRight: true })),
	);

	const updateScrollStates = (index: number) => {
		const container = scrollRefs.current[index];
		if (!container) return;

		setScrollStates((prevStates) =>
			prevStates.map((state, i) =>
				i === index
					? {
							canScrollLeft: container.scrollLeft > 0,
							canScrollRight: container.scrollLeft + container.clientWidth < container.scrollWidth,
					  }
					: state,
			),
		);
	};

	useEffect(() => {
		scrollRefs.current.forEach((container, index) => {
			if (container) updateScrollStates(index);
		});
	}, []);

	const onSelect = (selectedItem: ICategory) => {
		toggleWardrobeItemSelection({ category: selectedItem.category as string, itemName: selectedItem.name });
	};

	const handleScroll = (direction: 'left' | 'right', index: number) => {
		const container = scrollRefs.current[index];
		if (!container) return;

		const firstChild = container.firstChild as HTMLElement | null;
		const itemWidth = firstChild?.clientWidth || 0;

		const scrollAmount = itemWidth * ITEMS_PER_SCROLL;

		container.scrollBy({
			left: direction === 'right' ? scrollAmount : -scrollAmount,
			behavior: 'smooth',
		});

		setTimeout(() => updateScrollStates(index), 300);
	};

	const onReset = (sectionTitle: string) => {
		resetWardrobeItemsSelection(sectionTitle);
	};

	return (
		<div className={styles.myWardrobe__container}>
			{wardrobeSections?.map((item, index) => {
				const title = item[0];
				const list = item[1];
				const selectedItemsCount = list?.filter((item) => item.isSelected).length;

				return (
					<div className={styles.myWardrobe__section} key={`${title}_${index}`}>
						<Header
							canScrollLeft={scrollStates[index]?.canScrollLeft}
							canScrollRight={scrollStates[index]?.canScrollRight}
							selectedItemsCount={selectedItemsCount}
							title={title}
							onReset={() => onReset(title)}
							onScrollLeft={() => handleScroll('left', index)}
							onScrollRight={() => handleScroll('right', index)}
						/>
						<div
							className={styles.myWardrobe__cardsList}
							ref={(el: HTMLDivElement) => (scrollRefs.current[index] = el)}
						>
							{list.map((item, index) => (
								<div className={styles.myWardrobe__item} key={item.name + index}>
									<ClothingItemCard
										imageClassName={styles.myWardrobe__itemImage}
										isSelected={item.isSelected}
										item={item}
										onSelect={onSelect}
									/>
								</div>
							))}
						</div>
					</div>
				);
			})}
		</div>
	);
};

export default GroupedWardrobeSections;
