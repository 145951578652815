import { useService, useQuery } from '@hooks';
import { dayjs } from '@utils';
import type { UseQueryOptions } from '@tanstack/react-query';
import type { ICategory, TGender } from '@typings';

export const useGetCategoryQuery = <T = TUseGetCategoryByIdQueryData>(
	params: TUseGetCategoryQuery,
	queryOptions?: UseQueryOptions<TUseGetCategoryByIdQueryData, Error, T>,
) => {
	const api = useService('ApiService');

	return useQuery<TUseGetCategoryByIdQueryData, Error, T>(
		['getCategoryById', params.name, params.gender],
		() =>
			api.clothing.getCategoryById(
				params as {
					name: ICategory['name'];
					gender: TGender;
				},
			),
		{
			cacheTime: dayjs.duration(30, 'minutes').asMilliseconds(),
			staleTime: dayjs.duration(30, 'minutes').asMilliseconds(),
			enabled: !!params?.name,
			...queryOptions,
		},
	);
};

type TUseGetCategoryQuery = { name: ICategory['name'] | null; gender: TGender };
type TUseGetCategoryByIdQueryData = ICategory[] | undefined;
