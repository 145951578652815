import { Formik } from 'formik';
import { useTranslation, useScreenSize } from '@hooks';
import CategoryFilter from '@ui-modules/demo/components/CategoryFilter';
import ClothingSelector from '@ui-modules/demo/components/ClothingSelector';

import styles from './ClothingSelectionPage.module.css';
import { FormPageLayout, Page } from '../../components';

import type { ICategory, IClothingItem, TGender } from '@typings';
import { useClothingActions } from '@ui-modules/demo/hooks/useClothingActions';
import { INITIAL_GENDER, ROUTES } from '@constants';
import { noop } from '@utils';
import TourWrapper from '../../../ui-kit/components/TourWrapper';
import { getCategoriesTourSteps } from '@ui-modules/demo/utils/tourSteps';
import React from 'react';

const ClothingSelectionPage = () => {
	const { t } = useTranslation();

	const { width } = useScreenSize();

	const { selectedClothes, gender } = useClothingActions();

	const initialValues: TDemoFormValues = {
		gender: gender || INITIAL_GENDER,
		activeCategoryName: null,
		selectedClothes,
	};

	return (
		<TourWrapper steps={getCategoriesTourSteps(t, width > 1024)}>
			<Page title={t('Demo')}>
				<Formik<TDemoFormValues> initialValues={initialValues} onSubmit={noop}>
					{(formProps) => {
						return (
							<FormPageLayout<TDemoFormValues>
								allowedNextPaths={[ROUTES.home(), ROUTES.myWardrobe()]}
								formProps={formProps}
								ignoreDirty={false}
								isSaveButtonHidden={true}
							>
								<div className={styles.container}>
									{/* @note: On smaller screens this component will reappear in <ClothingSelector/> */}
									{/*@todo make the code look better, there */}
									{width > 1024 && <CategoryFilter formProps={formProps} />}
									<ClothingSelector formProps={formProps} />
								</div>
							</FormPageLayout>
						);
					}}
				</Formik>
			</Page>
		</TourWrapper>
	);
};

export type TDemoFormValues = {
	gender: TGender;
	activeCategoryName: ICategory['name'] | null;
	selectedClothes: IClothingItem[];
};

export interface IDemoPageParams extends Record<string, string | undefined> {
	id: string;
}

export default ClothingSelectionPage;
