import { useScreenSize, useTranslation } from '@hooks';
import styles from './Inspiration.module.css';
import InspirationCard from '@ui-modules/home/components/InspirationCard';
import {
	Button,
	ConfidenceIcon,
	DoodlesIcon,
	ExperienceIcon,
	GemIcon,
	HangersPrototypeIcon,
	MagicIcon,
	RefreshIcon,
} from '@ui-kit';
import { ROUTES } from '@constants';
import { useNavigate } from 'react-router-dom';

// @todo change this to make it work, this helps now with cases such as breaking ui icons [LOOK-12]
const UI_BREAKING_SCREEN_WIDTH = 900;
const Inspiration = () => {
	const { t } = useTranslation();
	const screenSize = useScreenSize();
	const navigate = useNavigate();

	const onDemoClick = () => {
		navigate(ROUTES.clothingSelection());
	};

	return (
		<section className={styles.inspiration}>
			{/*<AutoplayCarousel cardDetails={inspirationCardCarouselFirstSection} />*/}
			<div className={styles.inspiration__wrapper}>
				<div className={styles.inspiration__content}>
					<HangersPrototypeIcon className={styles.inspiration__hangersIcon} fill="#F88585" />

					<div className={styles.inspiration__headerWrapper}>
						<h2 className={styles.inspiration__header}>
							{t('Find your style')} <span className={styles['inspiration__header_highlight']}>{t('inspiration')}</span>
						</h2>
						{screenSize.width > UI_BREAKING_SCREEN_WIDTH && (
							<DoodlesIcon className={styles.inspiration__doodleIcon} fill={'#FDD6D6'} />
						)}
					</div>

					<div className={styles.inspiration__features}>
						<InspirationCard icon={<ExperienceIcon />} title={t('Effortless Styling')} />
						<InspirationCard icon={<RefreshIcon />} title={t('Maximize Wardrobe Usage')} />
						<InspirationCard icon={<ConfidenceIcon />} title={t('Confident Shopping')} />
					</div>

					<div className={styles.inspiration__cta}>
						<div className={styles.inspiration__ctaTextWrapper}>
							<h2 className={styles.inspiration__ctaText}>
								{t('Your wardrobe upgrade')}
								<span className={styles['inspiration__ctaText_highlight']}> {t('starts here!')}</span>
							</h2>
							{screenSize.width > UI_BREAKING_SCREEN_WIDTH && (
								<DoodlesIcon className={styles.inspiration__ctaDoodleIcon} fill={'#F55C5C'} width={41} />
							)}
						</div>
						<Button
							className={styles.inspiration__ctaPressable}
							icon={<MagicIcon fill="#141B24" />}
							size={screenSize.screen === 'm' ? 'large' : 'medium'}
							title={t('Try Demo Now')}
							onClick={onDemoClick}
						/>
					</div>
				</div>
			</div>
			{/*<AutoplayCarousel cardDetails={inspirationCardCarouselFirstSection} />*/}
		</section>
	);
};

export default Inspiration;
