import { AnimatePresence, motion } from 'motion/react';
import { CloseIcon } from '@ui-kit/icons';
import type { KeyboardEventHandler, MouseEventHandler, ReactNode } from 'react';
import { useEffect } from 'react';
import type { TMixpanelEvent } from '@typings';
import Button from '../Button';
import Portal from '../Portal';
import styles from './Modal.module.css';
import { clsx } from '@utils';

const Modal = ({
	visible,
	title,
	titleCentered = false,
	subTitle,
	confirmTitle,
	cancelTitle,
	onConfirm,
	onCancel,
	variant = 'medium',
	modalContent,
	confirmIcon,
	cancelMode = 'default',
	isConfirmLoading,
	isConfirmDisabled,
	confirmTrackingName,
	contentClassName,
	allowPropagation,
	confirmButtonClassName,
	onClose,
	subtitleClassName,
	customHeader,
	extraFooter,
}: IModalProps) => {
	useEffect(() => {
		if (visible) {
			document.body.style.overflow = 'hidden';
			document.documentElement.style.overflow = 'hidden';
		} else {
			document.body.style.overflow = '';
			document.documentElement.style.overflow = '';
		}

		return () => {
			document.body.style.overflow = '';
			document.documentElement.style.overflow = '';
		};
	}, [visible]);

	const handleClick: MouseEventHandler & KeyboardEventHandler = (event) => {
		if (!allowPropagation) event.stopPropagation();
	};

	return (
		<>
			{visible && (
				<Portal>
					<AnimatePresence>
						<div className={styles.modal__container}>
							<motion.div
								animate={{ opacity: 1, y: 0 }}
								exit={{ opacity: 0, y: -20 }}
								initial={{ opacity: 0, y: 20 }}
								transition={{ duration: 0.3, ease: 'backOut' }}
								onClick={handleClick}
								onKeyUp={handleClick}
							>
								<div className={clsx(styles.modal__content, contentClassName)} data-variant={variant}>
									{customHeader}
									<div className={styles.modal__titleWrapper}>
										{title ? (
											<span className={clsx(styles.modal__title, titleCentered && styles.modal__title_centered)}>
												{title}
											</span>
										) : null}
										{onClose && (
											<span
												aria-label="close"
												className={styles.modal__closeButton}
												role="button"
												tabIndex={-1}
												onClick={onClose}
												onKeyPress={onClose}
											>
												<CloseIcon height={16} width={16} />
											</span>
										)}
									</div>
									{subTitle ? (
										<span className={clsx(styles.modal__subTitle, subtitleClassName)}>{subTitle}</span>
									) : null}
									{modalContent}
									<div className={styles.modal__buttons}>
										{cancelTitle && onCancel ? (
											<Button title={cancelTitle} variant="tertiary" onClick={onCancel} />
										) : (
											<div />
										)}
										{confirmTitle && onConfirm ? (
											<Button
												className={confirmButtonClassName}
												disabled={isConfirmDisabled || isConfirmLoading}
												icon={confirmIcon}
												iconPosition={'right'}
												loading={isConfirmLoading}
												title={confirmTitle}
												trackingName={confirmTrackingName}
												variant="secondary"
												onClick={onConfirm}
											/>
										) : (
											<div />
										)}
									</div>
									{!!extraFooter && <div className={styles.modal__extraFooter}>{extraFooter}</div>}
								</div>
							</motion.div>
						</div>
					</AnimatePresence>
				</Portal>
			)}
		</>
	);
};

export interface IModalProps {
	confirmTitle?: string;
	confirmTrackingName?: TMixpanelEvent;
	cancelTitle?: string;
	visible: boolean;
	title?: string;
	titleCentered?: boolean;
	subTitle?: string;
	variant?: 'small' | 'medium' | 'large';
	onConfirm?: () => void;
	onCancel?: () => void;
	isConfirmLoading?: boolean;
	modalContent?: ReactNode;
	confirmIcon?: ReactNode;
	cancelMode?: 'default' | 'skip';
	onClose?: () => void;
	isConfirmDisabled?: boolean;
	contentClassName?: string;
	confirmButtonClassName?: string;
	allowPropagation?: boolean;
	subtitleClassName?: string;
	customHeader?: ReactNode;
	extraFooter?: ReactNode;
}

export default Modal;
